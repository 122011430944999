import React from 'react';
import axiosInstance from '../../containers/services/axiosInstance';
import NavbarI from './NavbarI';
import NavbarII from './NavbarII';
import { useSelector, useDispatch } from "react-redux";
import { updateApp } from '../../store/actions/auth';

export default function Navbar(props) {

    const { location } = props;
    const dispatch = useDispatch();
    const role = useSelector(state => state.authReducer.user.role !== "" ? state.authReducer.user.role : "guest");
    const approved = useSelector(state => state.authReducer.approved !== "" ? state.authReducer.approved : "");

    if (role == "service_provider" && !approved) {

        if (window.location.pathname !== "/pending" && window.location.pathname !== "/" && window.location.pathname !== "/service-provider/account") {

            axiosInstance().get("/approved")
                .then((response) => {
                    if (!response.data.data.approved) {
                        window.location.href = "/pending";
                    } else {
                        dispatch(updateApp());
                    }
                })

        }

    }


    if (location.pathname.match(/login/) || location.pathname.match(/register/)) {
        return null;
    }

    if (location.pathname.match(/admin/)) {
        if (role == "admin") {
            return null;
        }
    }



    return (
        <div className="nav-conatiner">
            <NavbarI />
            <NavbarII />
        </div>
    )
}

