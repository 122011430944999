import React from 'react';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import { Route, Switch } from "react-router-dom";
import M_Login from './M_Login';
import SP_Login from './SP_Login';

function Login() {

    const [activeIndex, setActiveIndex] = React.useState(null);

    const { t } = useTranslation();

    function tabsTemp() {

        return (

            <div className={activeIndex !== null ? "d-none auth-form tabs-container" : "auth-form tabs-container"}>

                <div className="title text-center">{t("SYC")}</div>

                <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>

                    <TabPanel header={t("migrant")} />

                    <TabPanel header={t("SP")} />

                </TabView>

                {/* <a href="/register" className="alreadyReg d-flex">{t("auth.register.create")}</a> */}

            </div>
        )
    }

    function handleTabChange(e) {
        e.index
            ?
            window.location.href = "login/service_provider"
            :
            window.location.href = "login/migrant"
    }

    return (
        <div className="register">

            <Switch>

                <Route exact path="/login/" component={tabsTemp} />

                <Route exact path="/login/service_provider" component={SP_Login} />

                <Route exact path="/login/migrant" component={M_Login} />

            </Switch>


        </div >
    )
}

export default Login;



