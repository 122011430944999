import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ServiceCategoryList = ({ onCategorySelect }) => {
  // State variables
  const [serviceCategories, setServiceCategories] = useState([]);
  const [Lang, setLang] = useState('en'); // Example: you can replace 'en' with dynamic language if needed

  // Predefined list of IDs (you can customize this list based on your API)
  const categoryIds = [1, 2, 3, 4, 5, 6, 7, 8,9,10]; 
  const [activeCategory, setActiveCategory] = useState(1);// Example category IDs to filter by

  // Function to fetch data from API based on serviceCategoryId
  const fetchServiceCategories = (id = '') => {
    const url = `/service-provider?serviceCategoryId=${id}`;
    axios
      .get(url, {
        headers: { 'locale': Lang },
      })
      .then((response) => {
        setServiceCategories(response.data.data.collection); // Set the filtered service categories
      })
      .catch((error) => {
        console.error('Error fetching service categories:', error);
      });
  };
 
  // Use effect to fetch all service categories on initial load
  useEffect(() => {
    fetchServiceCategories(1); // Fetch all categories initially (without filtering)
  }, [Lang]); // Re-fetch when language changes

  // Handle button click for filtering by ID
  const handleButtonClick = (id) => {
    if (onCategorySelect) {
      onCategorySelect(id); // Call the parent callback function to update the selected category ID
    }
    setActiveCategory(1);
    fetchServiceCategories();
     // Fetch filtered data based on selected ID
  };
  // Function to render category name based on ID
  const renderCategoryName = (id) => {
    switch (id) {
     case 1:
        return "CP";
      case 2:
        return "GBV";
      case 3:
        return "Cash Assistance";
      case 4:
        return "Health";
      case 5:
        return "MHPSS";
      case 6:
        return "Housing";
      case 7:
        return "Education";
      case 8:
        return "Livelihoods";
      case 9:
        return "Legal Assistance";
      case 10:
        return "Organizational Map";
      default:
        return id=1;
    }
  };

  return (
    <div>
      {/* <h1>Service Categories</h1> */}

      {/* Filter buttons based on predefined category IDs */}
      <div>
        {categoryIds.map((id) => {
          const isActive = activeCategory === id; 
          return (
            <button className="w3-button  mx-2 py-2 border border-primary bg-light rounded-pill active"
              key={id}
              onClick={() => handleButtonClick(id)}
              style={{ margin: "5px",
                padding: "15px",
               // marginBottom:"7%",
                marginLeft :"7%",
                marginRight :"7%",
                // backgroundColor: {isActive ? "#007bff" : "#e0e0e0"}, // Active button color (blue), inactive (gray)
                // color: {isActive ? "white" : "black"}, // Active button text color (white), inactive (black)
                border: "none",
                borderRadius: "5px" }}
            >
              {/* {id === 1 ? "CP" : id === 2 ? "GBV" :id=== 3 ? "Cash Assistance":id=== 4 ? "Health": id === 5 ?" MHPSS": id ===6 ?" Housing" : id=== 7 ?" Education" : id=== 8 ? "Livelihoods": id ===9 ?"Legal Assistance": id=== 10?"Organizational Map": id===1} */}
              {renderCategoryName(id)}

            </button>
  
          );
        })}
        
      </div>

      {/* Display service categories */}
      {/* <ul>
        {serviceCategories.length > 0 ? (
          serviceCategories.map((category) => (
            <li key={category.id}>{category.name} - phone: {category.phone}</li>
          ))
        ) : (
          <p>No categories found for this filter.</p>
        )}
      </ul> */}
    </div>
  );
};

export default ServiceCategoryList;


//  import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// const ServiceCategoryList = () => {
//   // State variables
//   const [serviceCategories, setServiceCategories] = useState([]);
//   const [selectedId, setSelectedId] = useState(null); // To keep track of the selected filter ID
//   const [Lang, setLang] = useState('en'); // Example: you can replace 'en' with dynamic language if needed

//   // Predefined list of IDs (you can customize this list based on your API)
//   const categoryIds = [1, 2, 3, 4, 5,6,7,8]; // Example category IDs to filter by

//   // Function to fetch data from API based on serviceCategoryId
//   const fetchServiceCategories = (id = '') => {
//     const url = `/service-provider?serviceCategoryId=${id}`;
//     axios
//       .get(url, {
//         headers: { 'locale': Lang },
//       })
//       .then((response) => {
//         setServiceCategories(response.data.data.collection); // Set the filtered service categories
//       })
//       .catch((error) => {
//         console.error('Error fetching service categories:', error);
//       });
//   };

//   // Use effect to fetch all service categories on initial load
//   useEffect(() => {
//     fetchServiceCategories(); // Fetch all categories initially (without filtering)
//   }, [Lang]); // Re-fetch when language changes

//   // Handle button click for filtering by ID
//   const handleButtonClick = (id) => {
//     setSelectedId(id);
//     fetchServiceCategories(id); // Fetch filtered data based on selected ID
//   };

//   return (
//     <div>
//       <h1>Service Categories</h1>

//       {/* Filter buttons based on predefined category IDs */}
//       <div>
//   {categoryIds.map((id) => {
//     // Conditionally render different buttons based on id
//     if (id === 1) {
//       return (
//         <button
//           key={id}
//           onClick={() => handleButtonClick(id)}
//           style={{ margin: "5px", padding: "10px" }}
//         >
//           CP
//         </button>
//       );
//     } else if (id === 2) {
//       return (
//         <button
//           key={id}
//           onClick={() => handleButtonClick(id)}
//           style={{ margin: "5px", padding: "10px" }}
//         >
//           Health
//         </button>
//       );
//     } else {
//       // Handle other ids or return nothing
//       return null;
//     }
//   })}
// </div>


//       {/* Display the selected filter ID for better UI feedback */}
//       {/* {selectedId && <p>Showing results for ID: {selectedId}</p>} */}

//       {/* Display service categories */}
//       <ul>
//         {serviceCategories.length > 0 ? (
//           serviceCategories.map((category) => (
//             <li key={category.id}>{category.name} - phone: {category.phone}</li>
//           ))
//         ) : (
//           <p>No categories found for this filter.</p>
//         )}
//       </ul>
//     </div>
//   );
// };
// // return (
// //     <div className="filters gray-bg py-lg-5 py-2">
// //         <div className="container">
// //             <Formik
// //                 initialValues={{
// //                     search: ""
// //                 }}

// //                 onSubmit={handleSubmit}
// //             >
// //                 {formBody}
// //             </Formik>
// //         </div>
// //     </div>
// // )

// export default ServiceCategoryList;
