import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pager from '../../components/Pager';
import { useTranslation } from 'react-i18next';
import Tags from '../../components/Tags';
import axiosInstance from "../services/axiosInstance";
import { NavLink } from 'react-router-dom';
import ServiceProviderCard from '../../components/Cards/ServiceProviderCard';
import ServiceCategoryList from '../../components/Tags';

function Service_providers_page({ isAdmin ,role}) {
  const { t } = useTranslation();
  const Lang = localStorage.getItem("i18nextLng");

  // State variables
  const [page, setPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [filterValues, setFilterValues] = useState([]);
  const [serviceProviderList, setServiceProviderList] = useState([]);
  const [totalServiceProviderList, setTotalServiceProviderList] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(1); // Track selected category

  const onBasicPageChange = (event) => {
    let eventPage = event.page + 1;
    setPage(eventPage);
    setBasicFirst(event.first);
    setPerPage(event.rows);
    loadServiceProviderList({ perPage: event.rows, page: eventPage });
  };

  // Function to load service providers for a specific category and page
  function loadServiceProviderList(params = {}) {
    params.perPage = params.perPage || perPage;
    params.page = params.page || page;
    params = { ...params, ...filterValues };

    setIsLoading(true);

    // Only make the request if a category is selected
    if (selectedCategoryId) {
      axiosInstance()
        .get(`/service-provider?serviceCategoryId=${selectedCategoryId}`, {
          params,
          headers: { 'locale': Lang },
        })
        .then((response) => {
          setServiceProviderList(response.data.data.collection);
          setTotalServiceProviderList(response.data.data.pageInfo.total);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error loading service providers:", error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    loadServiceProviderList(); // Trigger fetching based on initial selected category
  }, [Lang, selectedCategoryId]); // Fetch data on language change or category change

  const handleCategorySelect = (id) => {
    setSelectedCategoryId(id); // Update selected category ID
  };

  return (
    <div className="news">
      <div className="position-relative">

        {/* loader */}
        {
          isLoading
           &&
             <div className="text-center spinner-container">
                <i className="fas fa-circle-notch fa-spin fa-4x"></i>
             </div>
        }
        <div className={isAdmin ? "min-H pt-5 SP-Dash px-4" : "min-H container-fluid my-5 SP-Dash px-5"}>
             <div className="labels d-flex justify-content-between w-100 align-items-center mb-5">
                 <span className="h2 reset-bold text-capitalize main-title-res">{t("service_providers")}</span>
                 {isAdmin && <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to="/admin/news/add">{t("admin.addNew")}</NavLink></button>}
             </div>
             <ServiceCategoryList onCategorySelect={handleCategorySelect} />
                  <div className="cards_list service_cards row">
                       {serviceProviderList.length > 0 ? (
                          serviceProviderList.map((category) => (
                             <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4" key={category.id}>
                                <ServiceProviderCard serviceProvider={category} />
                             </div>
                        ))
                        ) : (
                      <p className="lead mx-3 no-cont">{t("noContent")}</p>
                      )}
                  </div>
        </div>
      </div>
      {/* Pager */}
      {serviceProviderList.length > 0 && (
        <Pager basicFirst={basicFirst} total={totalServiceProviderList} onPageChange={onBasicPageChange} />
      )}
    </div>
  );
}

export default Service_providers_page;


