import React from 'react';
import Icons from '../../../assets/Icons/pnge.png';
import { useTranslation } from 'react-i18next';

const ServiceProviderCard = ({ serviceProvider ,handleEdit,handleRemove ,role ,isAdmin}) => {
    const { t } = useTranslation('general');

    return (

        <a href={`/service-provider/${serviceProvider.id}`} className="d-block h-100">

             <div class="card h-100 service-card wow bounceInUp" data-wow-delay="0.1s">
                        <div class="bg-light rounded service-item">
                            <div class="service-content d-flex align-items-center justify-content-center p-4">
                                <div class="service-content-icon text-center">
                                <div class="service-img">
                                   <img src={serviceProvider.logo} class="img-fluid w-100 rounded-top" alt=""/>
                                </div>
                                     {/* <img class="imgservice"src={serviceProvider.logo}></img> */}
                                     {/* <i class="fas fa-donate fa-2x me-2">
                                        <img src= {I
                                        Icons} className="iconservice"/>
                                     </i> */}
                                     <div className="detaliscard">
                                        <h4 class="mb-3 card-title flex">{serviceProvider.name}</h4> 
                                        <p className=" mb-4 card-text">{serviceProvider.email}</p>
                                         {/* <p className=" mb-4 card-text">{serviceProvider.phone}</p> */}
                                   
                                        <a href="#" class="btn btn-primary px-4 py-2 rounded-pill">Read More</a>
                                     </div>
                                     
                                </div>
                            </div>
                        </div>
                    </div>
                    {isAdmin &&
                    
           
                    
                    <div className='news-options text-center'>
                        <button onClick={handleEdit} className='remove-button reset-button text-success px-0'>
                            <i className='fas fa-edit mx-2 d-inline-block' />
                            <span>{t('edit')}</span>
                        </button>
                        <button onClick={handleRemove} className='remove-button reset-button text-danger px-0'>
                            <i className='fas fa-trash mx-2 d-inline-block' />
                            <span>{t('remove')}</span>
                        </button>
                    </div>
                    
            }
        </a>
    )
}

export default ServiceProviderCard
