import React, { useEffect, useState } from 'react'
import { Field } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import FormikControl from './FormikControl';


export default function UploadDocument(props) {

  // language
  const lang = localStorage.getItem("i18nextLng");
const {t} = useTranslation()

  // lang conditions
  const noFile = lang !== "ar" ? "No File Selected" : "لم يتم اختيار اي ملف";

  const { label, typePos, type, title, sub_title, name, file_name, meta, question, index, value, ...rest } = props


  const [fileName, setFileName] = useState(value);
  const inputFile = React.useRef(null)
  const btnClicked = () => {
    inputFile.current.click()
  }
  useEffect(() => {
    typeof (value) == "object" ? setFileName(value.name) : setFileName(value)

  }, [value])


  return (

    <Field
      className="field"
      id={name}
      name={name}
      {...rest}>
      {({ form }) => (
        <div className="form-control d-flex flex-column upload-doc">
          {/* <label className="form-label" htmlFor="">{label}</label> */}
          <FormikControl
            control='input'
            name={`files.${index}.title`}
            placeholder={t('admin.file_title')}
            className="page-title d-flex" />
          <div className="d-flex align-items-center" style={{ "gap": "20px" }}>
            <button type="button" className="btn upload-doc-btn" onClick={btnClicked}><Trans i18nKey="chooseFile" /></button>
            <input
              type="file"
              className="d-none"
              accept=".pdf , .doc, .docx"
              ref={inputFile}
              onChange={(event) => {
                let file = event.currentTarget.files[0];
                setFileName(file.name)
                form.setFieldValue(props.name, file);
                typePos && form.setFieldValue(typePos, type);

              }}
            />
            <span className="file-name">{fileName !== "" ? fileName : noFile} </span>
          </div>




        </div>


      )}
    </Field>



  )
}