import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown, ButtonGroup, Button, Badge } from "react-bootstrap";
import axiosInstance from "../../containers/services/axiosInstance";
import { logout } from "../../store/actions/auth";
import { useDispatch } from "react-redux";
import axios from "axios";
import { clearTemp } from "../../store/actions/temp";

function NavbarII() {

  const { t } = useTranslation();

  // make the actions on the state
  const dispatch = useDispatch();

  const toggleRef = React.useRef(null);

  const [isActive, setIsActive] = React.useState(false);

  const authReducer = useSelector((state) => state.authReducer);

  const access_token = useSelector((state) => state.authReducer.access_token);

  const role = useSelector((state) => state.authReducer.user && state.authReducer.user.role);


  const handleLogout = async () => {
    try {

      let headers = {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${access_token}`,
        }
      }

      const resp = await axios.post("logout", {}, headers);

      window.location = '/login/service_provider';
      dispatch(logout());
      dispatch(clearTemp());

    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  function handleToggle() {
    setIsActive(!isActive);
  }

  function loggedInNav_AD() {
    return (
      <div className="second-nav">

        <div className="container-fluid px-md-5">

          <div className="row">
            <div className="colI col col-lg-2">
              <button onClick={handleToggle} className="reset-button d-lg-none">
                <i className="fas fa-bars"></i>
              </button>
              <NavLink to="/">
                <img src="/img/home/b-logo.svg" alt="logo" />
              </NavLink>
            </div>

            <div className="colII col col-lg-8 d-none d-lg-block">
              <NavLink to="/admin/statistics">{t("admin.statistics")}</NavLink>
              <NavLink to="/admin/service-providers">{t("nav2.a_3")}</NavLink>
              {/* <NavLink to="/admin/news">{t("nav2.a_5")}</NavLink> */}
              {/* <NavLink to="/admin/resources">{t("admin.resources")}</NavLink> */}
            </div>

            <div className="col col-lg-2 text-end">
              <Dropdown className="drop-listII" as={ButtonGroup}>
                <Dropdown.Toggle
                  split
                  variant="transparent"
                  style={{ fontSize: "15.5px" }}
                >
                  <span>
                    {t("welcome")},&nbsp;{" "}
                    <b>{authReducer.user.name.split(" ")[0]}</b>&nbsp;{" "}
                  </span>
                  <i className="fas fa-chevron-down"></i>{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu id="dropdown-button-drop-up">
                  <Dropdown.Item id="logout" onClick={handleLogout}>
                    <NavLink to="#">{t("logout")}</NavLink>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

        </div>

        <div className={`colII small-nav d-lg-none d-flex ${isActive ? "active" : ""}`}>
          <button onClick={handleToggle} className="reset-button d-lg-none">
            <i className="fas fa-times"></i>
          </button>
          <NavLink exact to="/">
            {t("nav2.a_1")}
          </NavLink>
          {/* <NavLink to="/services">{t("services")}</NavLink> */}
          <NavLink to="/service-providers">{t("nav2.a_3")}</NavLink>
          {/* <NavLink to="/events">{t("nav2.a_4")}</NavLink> */}
          {/* <NavLink to="/news">{t("nav2.a_5")}</NavLink> */}
          <NavLink to="/resources">{t("admin.resources")}</NavLink>
          <NavLink to="/referrals">{t("nav2.a_6")}</NavLink>
        </div>

      </div>
    );
  }


  const Lang = localStorage.getItem("i18nextLng");

  const [notiList, setNotiList] = React.useState([]);
  const [nextPage, setNextPage] = React.useState(2);
  const [total, setTotal] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);

  useEffect(() => {

    authReducer.access_token
      &&
      axiosInstance().get("/notifications", { 'headers': { 'locale': Lang } })
        .then((response) => {
          setTotal(response.data.data.pageInfo.total)
          setNotiList(response.data.data.collection);
          if (response.data.data.pageInfo.totalPages == 1) {
            setIsDisabled(true)
          }
        })


  }, [])

  const [isLoading, setIsLoading] = React.useState(false);


  function loggedInNav_SP(nextPage, isDisabled) {

    function handleMore() {

      if (!isDisabled) {

        setIsLoading(true)
        setIsDisabled(true)

        axiosInstance().get(`/notifications?page=${nextPage}`, { 'headers': { 'locale': Lang } })
          .then((response) => {

            setIsDisabled(false)
            notiList.push(...response.data.data.collection)
            setNotiList([...notiList]);
            setIsLoading(false);

            nextPage = nextPage + 1

            if (nextPage <= response.data.data.pageInfo.totalPages) {
              setNextPage(nextPage)
            } else {
              setIsDisabled(true)
            }

          })
          .catch(() => {
            setIsLoading(false);
            setIsDisabled(false)
          })

      }

    }

    function handleClick(id, type, { ...rest }) {

      axiosInstance().get(`/notifications/read/${id}`)
        .then((response) => {
          setTotal(response.data.data.pageInfo.total)
          setNotiList(response.data.data.collection);
          if (response.data.data.pageInfo.totalPages == 1) {
            setIsDisabled(true)
          }
          switch (type) {

            case "migrant_applied":
              window.location.href = `/services/service/${rest.service.id}`
              break;

            case "event_migrant_applied":
              window.location.href = `/events/event/${rest.event.id}`
              break;

            case "referral_requested":
              window.location.href = `/referral/${rest.referral_id}`
              break;

            case "referral_changed":
              window.location.href = `/referrals`
              break;

            default:
              break;
          }
        })

    }

    function handleRemove() {

      total !== 0
        &&
        axiosInstance().get("/notifications/clear-all")
          .then(() => {
            setTotal(0)
            setNotiList([]);
            setIsDisabled(true)
          })

    }

    return (

      <div className="second-nav">

        <div className="container-fluid px-md-5">

          <div className="row">

            <div className="colI col col-lg-2">
              <button onClick={handleToggle} className="reset-button d-lg-none">
                <i className="fas fa-bars"></i>
              </button>
              <NavLink to="/">
                <img src="/img/home/b-logo.svg" alt="logo" />
              </NavLink>
            </div>

            <div className="colII col col-lg-8 col-xl-7 d-none d-lg-block">
              <NavLink exact to="/">
                {t("nav2.a_1")}
              </NavLink>
              {/* <NavLink to="/services">{t("services")}</NavLink> */}
              <NavLink to="/service-providers">{t("nav2.a_3")}</NavLink>
              {/* <NavLink to="/events">{t("nav2.a_4")}</NavLink> */}
              {/* <NavLink to="/news">{t("nav2.a_5")}</NavLink> */}
              <NavLink to="/resources">{t("admin.resources")}</NavLink>
              <NavLink to="/referrals">{t("nav2.a_6")}</NavLink>
            </div>

            <div className="col col-lg-2 col-xl-3 text-end sp-end-col">

              <Dropdown className="drop-listII noti-list" as={ButtonGroup}>

                <Dropdown.Toggle
                  split
                  variant="transparent"
                  style={{ fontSize: "15.5px" }}
                >
                  <Button variant="primary" className="notification">
                    <i className="pi pi-bell p-mr-4 p-text-secondary p-overlay-badge" style={{ fontSize: '1rem', color: "#fff" }}><Badge value={total} ></Badge></i>
                    <Badge bg="secondary">{total}</Badge>
                    <span className="visually-hidden">unread messages</span>
                  </Button>
                </Dropdown.Toggle>

                <Dropdown.Menu className="py-0" id="dropdown-button-drop-up">

                  {
                    total !== 0
                    &&
                    <div className="text-end clearAll text-danger reset_button p-0 mb-0 w-100 d-block">
                      <span onClick={handleRemove} className="cursor-pointer">{t("clearAll")}</span>
                    </div>
                  }

                  {
                    notiList.length > 0
                    &&
                    notiList.map(item => (
                      <Dropdown.Item key={item.id} className="p-2 cursor-pointer" onClick={() => handleClick(item.id, item.type, { ...item.data })}>
                        <li className="cursor-pointer">{item.message}</li>
                      </Dropdown.Item>
                    ))
                  }

                  <button onClick={handleMore} className={`${isDisabled ? "disabled-overlay" : ""} seeMore text-center reset_button mb-0 w-100 d-block p-0`}>{t("seeMore")}
                    {
                      isLoading
                      &&
                      <i className="fas fa-circle-notch fa-spin fa-lg"></i>
                    }
                  </button>

                </Dropdown.Menu>

              </Dropdown>

              <Dropdown className="drop-listII" as={ButtonGroup}>
                <Dropdown.Toggle
                  split
                  variant="transparent"
                  style={{ fontSize: "15.5px" }}
                >
                  <span>
                    {t("welcome")},&nbsp;{" "}
                    <b>{authReducer.user.name.split(" ")[0]}</b>&nbsp;{" "}
                  </span>
                  <i className="fas fa-chevron-down"></i>{" "}
                </Dropdown.Toggle>
                <Dropdown.Menu id="dropdown-button-drop-up">
                  <Dropdown.Item id="dashboard">
                    <NavLink to="/service-provider/dashboard/services">{t("dashboard")}</NavLink>
                  </Dropdown.Item>

                  <Dropdown.Item id="settings">
                    <NavLink to="/service-provider/account/edit">{t("account")}</NavLink>
                  </Dropdown.Item>

                  <Dropdown.Item id="logout" onClick={handleLogout}>
                    <NavLink to="#">{t("logout")}</NavLink>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

            </div>

          </div>

        </div>

        <div className={`colII small-nav d-lg-none d-flex ${isActive ? "active" : ""}`}>
          <button onClick={handleToggle} className="reset-button d-lg-none">
            <i className="fas fa-times"></i>
          </button>
          <NavLink exact to="/">
            {t("nav2.a_1")}
          </NavLink>
          {/* <NavLink to="/services">{t("services")}</NavLink> */}
          <NavLink to="/service-providers">{t("nav2.a_3")}</NavLink>
          {/* <NavLink to="/events">{t("nav2.a_4")}</NavLink> */}
          <NavLink to="/news">{t("nav2.a_5")}</NavLink>
          {/* <NavLink to="/resources">{t("admin.resources")}</NavLink> */}
          <NavLink to="/referrals">{t("nav2.a_6")}</NavLink>
        </div>

      </div>
    );
  }

  function loggedInNav_M() {
    return (
      <div className="second-nav">

        <div className="container-fluid px-md-5">

          <div className="row">
            <div className="colI col col-lg-3">
              <button onClick={handleToggle} className="reset-button d-lg-none">
                <i className="fas fa-bars"></i>
              </button>
              <NavLink to="/">
                <img src="/img/home/b-logo.svg" alt="logo" />
              </NavLink>
            </div>

            <div className="colII col col-lg-6 d-none d-lg-block">
              <NavLink exact to="/">
                {t("nav2.a_1")}
              </NavLink>
              {/* <NavLink to="/services">{t("services")}</NavLink> */}
              <NavLink to="/service-providers">{t("nav2.a_3")}</NavLink>
              {/* <NavLink to="/events">{t("nav2.a_4")}</NavLink> */}
              <NavLink to="/resources">{t("admin.resources")}</NavLink>
              <NavLink to="/news">{t("nav2.a_5")}</NavLink>
            </div>

            <div className="col col-lg-3 text-end">
              <Dropdown className="drop-listII" as={ButtonGroup}>
                <Dropdown.Toggle
                  split
                  variant="transparent"
                  style={{ fontSize: "15.5px" }}
                >
                  <span>
                    {t("welcome")},&nbsp;{" "}
                    <b>{authReducer.user.name.split(" ")[0]}</b>&nbsp;{" "}
                  </span>
                  <i className="fas fa-chevron-down"></i>{" "}
                </Dropdown.Toggle>
                <button ref={toggleRef} className="d-none"></button>
                <Dropdown.Menu id="dropdown-button-drop-up" className="M-DL p-3 pb-2 reset-LH">

                  <h5>{authReducer.user.name}</h5>
                  <p>{authReducer.user.email}</p>
                  <p>{authReducer.user.phone}</p>
                  <hr />
                  <div
                    className={`d-inline-flex w-100 justify-content-between`}
                  >
                    <span onClick={() => toggleRef.current.click()} className="d-inline-block" ><NavLink to="/migrant/account">{t("editP")}</NavLink></span>
                    <button onClick={handleLogout} className="d-inline-block reset-button" >{t("logout")}</button>
                  </div>

                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

        </div>

        <div className={`colII small-nav d-lg-none d-flex ${isActive ? "active" : ""}`}>
          <button onClick={handleToggle} className="reset-button d-lg-none">
            <i className="fas fa-times"></i>
          </button>
          <NavLink exact to="/">
            {t("nav2.a_1")}
          </NavLink>
          {/* <NavLink to="/services">{t("services")}</NavLink> */}
          <NavLink to="/service-providers">{t("nav2.a_3")}</NavLink>
          {/* <NavLink to="/events">{t("nav2.a_4")}</NavLink> */}
          <NavLink to="/resources">{t("admin.resources")}</NavLink>
          <NavLink to="/news">{t("nav2.a_5")}</NavLink>
        </div>

      </div>
    );
  }

  function loggedOutNav() {
    return (
      <div className="second-nav">

        <div className="container-fluid px-md-5">

          <div className="row">
            <div className="colI col col-lg-2">
              <button onClick={handleToggle} className="reset-button d-lg-none">
                <i className="fas fa-bars"></i>
              </button>
              <NavLink to="/">
                <img src="/img/home/b-logo.svg" alt="logo" />
              </NavLink>
            </div>

            <div className="colII col col-lg-7 d-none d-lg-block">
              <NavLink exact to="/">
                {t("nav2.a_1")}
              </NavLink>
              {/* <NavLink to="/services">{t("services")}</NavLink> */}
              <NavLink to="/service-providers">{t("nav2.a_3")}</NavLink>
              {/* <NavLink to="/events">{t("nav2.a_4")}</NavLink> */}
              <NavLink to="/resources">{t("admin.resources")}</NavLink>
              {/* <NavLink to="/news">{t("nav2.a_5")}</NavLink> */}
            </div>

            <div className="colIII col col-lg-3">
              {/* <NavLink to="/register">{t("register")}</NavLink> */}
              <NavLink to="/login/service_provider">{t("login")}</NavLink>
            </div>

          </div>

        </div>

        <div className={`colII small-nav d-lg-none d-flex ${isActive ? "active" : ""}`}>
          <button onClick={handleToggle} className="reset-button d-lg-none">
            <i className="fas fa-times"></i>
          </button>
          <NavLink exact to="/">
            {t("nav2.a_1")}
          </NavLink>
          {/* <NavLink to="/services">{t("services")}</NavLink> */}
          <NavLink to="/service-providers">{t("nav2.a_3")}</NavLink>
          {/* <NavLink to="/events">{t("nav2.a_4")}</NavLink> */}
          <NavLink to="/resources">{t("admin.resources")}</NavLink>
          {/* <NavLink to="/news">{t("nav2.a_5")}</NavLink> */}
        </div>

      </div>
    );
  }

  return authReducer.access_token ? role == "migrant" ? loggedInNav_M() : role == "admin" ? loggedInNav_AD() : loggedInNav_SP(nextPage, isDisabled, total) : loggedOutNav();
}

export default NavbarII;
