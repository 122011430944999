import React from 'react';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
export default function MediaView(props) {

    return (
        <>
        {/* TODO FIX VIDEO ALIGN */}
            {props.isOpen &&
                <Lightbox
                    mainSrc={props.media[props.index]}
                    nextSrc={props.media[(props.index + 1) % Object.keys(props.media).length]}
                    prevSrc={props.media[(props.index + Object.keys(props.media).length - 1) % Object.keys(props.media).length]}
                    onCloseRequest={() => props.handleClose(false)}
                    onMovePrevRequest={() =>
                        props.handleChange((props.index + Object.keys(props.media).length - 1) % Object.keys(props.media).length)
                    }
                    onMoveNextRequest={() =>
                        props.handleChange((props.index + 1) % Object.keys(props.media).length)
                    }
                />
            }
        </>
    )
}

