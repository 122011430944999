import React from 'react';
import { NavLink, Switch, Route } from "react-router-dom";
import Events from './components/Events';
import Services from './components/Services';
import { useTranslation } from "react-i18next";

function Dashboard() {

    const { t } = useTranslation();

    const [activeIndex, setActiveIndex] = React.useState(window.location.href.indexOf("events") > -1);

    function handleTabChange(e) {
        setActiveIndex(e);
    }

    return (
        <div className="position-relative">

            <div className="min-H SP-Dash container-fluid px-md-5 mt-5">

                <div className="dash-head mb-3 mb-lg-5">

                    <div className="tabs-container">
                        <NavLink className="main-buttonII d-inline-block" onClick={() => handleTabChange(0)} to={"/service-provider/dashboard/services"}>{t("services")}</NavLink>
                        <NavLink className="main-buttonII d-inline-block" onClick={() => handleTabChange(1)} to={"/service-provider/dashboard/events"}>{t("events")}</NavLink>
                    </div>

                    <NavLink className="main-buttonII px-5 add-new" to={`/service-provider/dashboard/${!activeIndex ? "services" : "events"}/addition`}>{t("add")}</NavLink>
                </div>

                <Switch>

                    <Route
                        path="/service-provider/dashboard/services"
                        render={() => <Services />}
                    />

                    <Route
                        path="/service-provider/dashboard/events"
                        render={() => <Events />}
                    />

                </Switch>

            </div >
        </div>
    )
}

export default Dashboard
