import React from 'react';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setIsLoading } from '../../../store/actions/Loading';
import { auth } from '../../../store/actions/auth';
import { Toast } from 'primereact/toast';

function SP_Login() {

    const { t } = useTranslation();

    // make the actions on the state
    const dispatch = useDispatch();

    const toast = React.useRef(null);


    function handleSubmit(values) {

        axios.post("/login", values)
            .then(response => {
                // axios.defaults.headers.common["Authorization"] = `Bearer ${response.data.data.access_token}`

                dispatch(setIsLoading(false));
                dispatch(auth(response.data.data));

                if (response.data.data.user.role == "admin") {
                    window.location.href = "/admin/news";
                } else {
                    window.location.href = "/admin/news";
                }

                // dispatch(login(response.data.data));
                // dispatch(setIsLoading(false));
                // dispatch(setTempURL(""));
                // window.localStorage.setItem("rcml-lang", response.headers.locale);
                // redirectionControl(response);
            })
            .catch(err => {
                dispatch(setIsLoading(false));
                if (err.response !== null & err.response !== undefined) {
                    err.response.data.errors
                        ?
                        toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors) })
                        :
                        toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                } else {
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                }
            });
    }

    function formBody({ handleSubmit }) {

        return (

            <form className="auth-form" onSubmit={handleSubmit}>

                {/* <Messages ref={msgs1} /> */}

                <div className="title">{t("auth.login.title")}</div>

                <div className="row mb-4 no-gutters">

                    <label>{t("auth.login.mail")}</label>
                    <Field
                        className="w-100 input-style"
                        type="email"
                        name="email"
                        icon="far fa-envelope"
                        placeholder={t("auth.login.mailPH")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="email" />
                    </div>
                </div>

                <div className="row no-gutters">
                    <label>{t("auth.login.password")}</label>
                    <Field
                        className="w-100 input-style"
                        type="password"
                        name="password"
                        icon="fas fa-unlock-alt"
                        placeholder={t("auth.login.passwordPH")}
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="password" />
                    </div>
                </div>

                <div style={{ "textAlign": "end" }}>
                    {/* <a className="main-color forget-a" href="/forgetpassword">{t("auth.login.passwordForget")}</a> */}
                    <button type="submit" className="mt-4 d-block w-100 main-buttonII">{t("auth.login.title")}</button>
                </div>

            </form>
        )
    }

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({
            email: Yup.string().required(t("req")),
            password: Yup.string().required(t("req")),
        })
        return schema;
    }

    return (
        <div className="login">

            <Toast position="top-center" ref={toast} />

            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    role: "admin"
                }}

                onSubmit={handleSubmit}
                validationSchema={handleValidation}

            >
                {formBody}
            </Formik>
        </div>
    )
}

export default SP_Login
