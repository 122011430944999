import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../services/axiosInstance';
import { Toast } from 'primereact/toast';
import { useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from "yup";
import { TabView, TabPanel } from "primereact/tabview";
import moment from 'moment';


function ReferralForm() {

    const { t } = useTranslation();

    const toast = React.useRef(null);

    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    // saved states
    const ID = useSelector((state) => state.authReducer.service_provider_id);
    const SP_Name = useSelector((state) => state.authReducer.user.name);

    // gets data
    const [getFData, setFGetData] = React.useState({
        asylum_types: [],
        focal_points: [],
    });
    const [getTData, setTGetData] = React.useState({
        service_providers: [],
        focal_points: [],
    });

    // from data
    const [fromData, setFromData] = React.useState({
        position: "position",
        contact: "contacts"
    });

    // to data
    const [toData, setToData] = React.useState({
        position: "position",
        contact: "contacts"
    });


    const [beneCode, setBeneCode] = React.useState("");

    // date code
    const [years, setYears] = React.useState([]);
    const [monthes, setMonthes] = React.useState(moment.monthsShort());
    const [days, setDays] = React.useState([]);

    let currentYear = new Date().getFullYear();
    let currentMonth = moment().format('MMM');
    let currentDay = new Date().getDate();

    // ("Feb").format("M")

    // beneficiary Data
    const [beneficiaryData, setBeneficiaryData] = React.useState({
        beneficiary_name: "",
        beneficiary_phone: "",
        id_number: "",
        beneficiary_address: "",
        birth_day: currentDay,
        birth_month: currentMonth,
        birth_year: currentYear,
        beneficiary_gender: "",
        language: "",
        asylum_type_id: "",
    });

    // gender options
    const [genderList, setGenderList] = useState([
        {
            id: "male",
            name: t("male"),
        },
        {
            id: "female",
            name: t("female"),
        },
        {
            name: t("other"),
        }
    ]);

    // langs options
    const [langsList, setLangsList] = useState([
        {
            id: "en",
            name: "English",
        },
        {
            id: "ar",
            name: "العربية",
        },
        {
            id: "fr",
            name: "French",
        },
        {
            id: "other",
            name: t("other"),
        },
    ]);

    // refs
    const formRef = React.useRef();
    const focal_ref = React.useRef();

    const SP_ref = React.useRef();
    const focal_ref_to = React.useRef();

    useEffect(() => {

        axiosInstance().get("/asylum-types", { 'headers': { 'locale': Lang } })
            .then((res) => {
                getFData.asylum_types = res.data.data;
                setFGetData(getFData)
            })
            .catch((error) => {
                console.log(error);
            })

        // for to contact
        axiosInstance().get("/service-provider", { 'params': { perPage: 100, excludeSelf: true }, 'headers': { 'locale': Lang } })
            .then((res) => {
                getTData.service_providers = res.data.data.collection;
                setTGetData({ ...getTData });
                SP_ref.current.disabled = false
            })
            .catch((error) => {
                console.log(error);
            })

        axiosInstance().get(`/service-provider/${ID}/focal`, { 'headers': { 'locale': Lang } })
            .then((res) => {
                setIsLoading(false)
                getFData.focal_points = res.data.data;
                setFGetData({ ...getFData });
                focal_ref.current.disabled = false
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })

        // set date 
        for (let i = currentYear; i >= 1905; i--) {
            years.push(i)
        }

        for (let i = 1; i <= 31; i++) {
            days.push(i)
        }

    }, [Lang]);

    // handle change
    function handleChange(e, name) {
        formRef.current.setFieldValue(name, e.target.value);

        switch (name) {

            case "from_focal_point_id":
                getFData.focal_points.map(focal => {
                    if (focal.id == e.target.value) {
                        fromData.position = focal.position;
                        fromData.contact = focal.phone;
                    }
                })
                setFromData(fromData)
                break;

            case "to_service_provider_id":

                focal_ref_to.current.disabled = true;

                // reset deps
                formRef.current.setFieldValue("to_focal_point_id", "");
                formRef.current.setFieldValue(name, e.target.value);

                focal_ref_to.current.selectedIndex = 0;

                axiosInstance().get(`/service-provider/${e.target.value}/focal`, { 'headers': { 'locale': Lang } })
                    .then((res) => {
                        getTData.focal_points = res.data.data;
                        setTGetData({ ...getTData })
                        focal_ref_to.current.disabled = false;
                        setToData({
                            position: "position",
                            contact: "contacts"
                        })
                    })
                break;

            case "to_focal_point_id":
                getTData.focal_points.map(focal => {
                    if (focal.id == e.target.value) {
                        toData.position = focal.position;
                        toData.contact = focal.phone;
                    }
                })
                setToData(toData)
                break;

            case "beneficiary_name":
            case "beneficiary_phone":
            case "beneficiary_address":
            case "beneficiary_gender":
            case "id_number":
            case "birth_day":
            case "birth_year":
            case "language":
            case "asylum_type_id":
                beneficiaryData[name] = e.target.value;
                setBeneficiaryData(beneficiaryData);
                break;

            case "birth_month":
                beneficiaryData[name] = e.target.value;
                setBeneficiaryData(beneficiaryData);
                formRef.current.setFieldValue(name, moment().month(e.target.value).format("M"));
                break;

            default:
                break;
        }
    }

    // handle submit
    function handleSubmit(values) {

        setIsLoading(true);

        let year = values.birth_year;
        let month = values.birth_month;
        let day = values.birth_day;

        if (day < 10) {
            day = `0${day}`;
        }

        if (month < 10) {
            month = `0${month}`;
        }

        if (!values.beneficiary_code) {
            values.beneficiary_birthdate = `${year}-${month}-${day}`
        }

        const formData = new FormData();

        for (const [key, val] of Object.entries(values)) {
            if (val !== null || val !== "") {
                formData.append(key, val);
            }
        }

        axiosInstance().post(`/referral`, formData)
            .then(() => {
                setIsLoading(false);
                toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                delete values["beneficiary_birthdate"];
                setTimeout(() => {
                    window.location.href = "/referrals";
                }, 2000);
            })
            .catch(err => {
                setIsLoading(false);
                if (err.response !== null & err.response !== undefined) {
                    err.response.data.errors
                        ?
                        toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors) })
                        :
                        toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                } else {
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: t("tryLater") })
                }
                delete values["beneficiary_birthdate"]

            })

    }

    let initialValues = {

        from_focal_point_id: "",
        to_service_provider_id: "",
        to_focal_point_id: "",


        beneficiary_informed: "",
        beneficiary_informed_explain: "",

        beneficiary_signed: "",
        beneficiary_signed_explain: "",

        beneficiary_restrictions: "",
        beneficiary_restrictions_explain: "",

        beneficiary_code: "",
        beneficiary_name: "",
        beneficiary_phone: "",
        beneficiary_address: "",

        birth_year: currentYear,
        birth_month: moment().month(currentMonth).format("M"),
        birth_day: currentDay,

        beneficiary_gender: "",
        language: "",
        asylum_type_id: "",

        caregiver_name: "",
        caregiver_relation: "",
        caregiver_contact_info: "",

        caregiver_informed: "",
        caregiver_informed_explain: "",

        description: "",
        priority: ""

    };

    // checkboxs
    const [activeIndex, setActiveIndex] = React.useState(null);
    const [activeIndexI, setActiveIndexI] = React.useState(null);
    const [activeIndexII, setActiveIndexII] = React.useState(null);
    const [activeIndexIII, setActiveIndexIII] = React.useState(null);

    const checkState = {
        'beneficiary_informed': activeIndex,
        'beneficiary_signed': activeIndexI,
        'beneficiary_restrictions': activeIndexII,
        'caregiver_informed': activeIndexIII
    }
    const checkFuncMap = {
        'beneficiary_informed': setActiveIndex,
        'beneficiary_signed': setActiveIndexI,
        'beneficiary_restrictions': setActiveIndexII,
        'caregiver_informed': setActiveIndexIII
    }

    function handleTabChange(e, form, name) {

        checkFuncMap[name](e.index);
        form.setFieldValue(name, e.index == 0 ? 1 : 0);

    }

    const checkboxTemp = (name) => {
        return (
            <>
                <Field name="e">
                    {({ form }) => (
                        <TabView
                            activeIndex={checkState[name]}
                            onTabChange={(e) => handleTabChange(e, form, name)}
                        >
                            <TabPanel header={t("yes")} />

                            <TabPanel header={t("no")} />

                        </TabView>
                    )}
                </Field>
                <div className="mt-2 text-danger">
                    <ErrorMessage name={name} />
                </div>
            </>
        )
    }

    // bene code
    const [isValid, setIsValid] = React.useState(null);

    function handleCode() {
        beneCode !== ""
            &&
            axiosInstance().get(`/referral/beneficiary/${beneCode}`, { 'headers': { 'locale': Lang } })
                .then((res) => {
                    if (res.data) {
                        setIsValid(true);

                        let data = res.data.data;
                        beneficiaryData.beneficiary_name = data.beneficiary_name;
                        beneficiaryData.id_number = data.id_number;
                        beneficiaryData.beneficiary_phone = data.beneficiary_phone;
                        beneficiaryData.beneficiary_address = data.beneficiary_address;

                        beneficiaryData.birth_day = moment(data.beneficiary_birthdate).date();
                        beneficiaryData.birth_month = moment(data.beneficiary_birthdate).format('MMM');
                        beneficiaryData.birth_year = moment(data.beneficiary_birthdate).format('Y');

                        beneficiaryData.beneficiary_gender = data.beneficiary_gender;
                        beneficiaryData.language = data.language;
                        beneficiaryData.asylum_type_id = data.asylum_type.id;

                        if (beneficiaryData.birth_day < 10) {
                            beneficiaryData.birth_day = parseInt(`0${beneficiaryData.birth_day}`);
                        }

                        let values = formRef.current.values;

                        formRef.current.resetForm({
                            values: {
                                ...values,
                                beneficiary_name: beneficiaryData.beneficiary_name,
                                beneficiary_phone: beneficiaryData.beneficiary_phone,
                                beneficiary_address: beneficiaryData.beneficiary_address,
                                beneficiary_address: beneficiaryData.beneficiary_address,
                                language: beneficiaryData.language,
                                beneficiary_gender: beneficiaryData.beneficiary_gender,
                                asylum_type_id: beneficiaryData.asylum_type_id
                            }
                        })


                        formRef.current.setFieldValue("beneficiary_code", beneCode.replace(/\s+/g, ''));

                        setBeneficiaryData({ ...beneficiaryData })
                    } else {
                        setIsValid(false);
                    }
                })
                .catch(() => {
                    setIsValid(false);
                })
    }

    function handleRemove() {
        setBeneCode("");
        setIsValid(null);
        setBeneficiaryData({
            beneficiary_name: "",
            beneficiary_phone: "",
            beneficiary_address: "",
            birth_day: currentDay,
            birth_month: currentMonth,
            birth_year: currentYear,
            beneficiary_gender: "",
            language: "",
            asylum_type_id: "",
        });

        let values = formRef.current.values;

        formRef.current.resetForm({
            values: {
                ...values,
                beneficiary_code: "",
                beneficiary_name: "",
                beneficiary_phone: "",
                beneficiary_address: "",
                birth_year: currentYear,
                birth_month: moment().month(currentMonth).format("M"),
                birth_day: currentDay,
                language: "",
                beneficiary_gender: "",
                asylum_type_id: ""
            }
        })
    }

    function formBody({ handleSubmit, values }) {
        return (
            <Form onSubmit={handleSubmit}>

                <div className="px-2 mt-5">
                    <div className="mb-3">

                        <div className="labels d-flex justify-content-between w-100">
                            <label>Priority</label>
                            <label>الاولوية</label>
                        </div>

                        <select
                            value={values.priority}
                            className="w-100 input-style"
                            style={{ marginTop: "1px" }}
                            onChange={(e) =>
                                handleChange(e, "priority")
                            }
                        >
                            <option value="" disabled selected>
                                {t("referral.priority")}
                            </option>
                            <option value="high">
                                {t("referral.high")}
                            </option>
                            <option value="med">
                                {t("referral.med")}
                            </option>
                            <option value="low">
                                {t("referral.low")}
                            </option>

                        </select>

                    </div>
                </div>

                {/* 1st row */}
                <div className="row">

                    <div className="from-col col-lg-6 px-lg-4 px-3">

                        <h2 className="h1 reset-bold title-color form-head">REFERRAL FORM</h2>
                        <p className="text-start ref-hint">Referred by: specify name of agency making referral and name of person making  the referral</p>
                        <p className="text-end ref-hint">إحالة من: تحديد اسم المنظمة المُرسِلة والشخص المسؤول الذي قام بالاحالة </p>

                        <div className="selects mt-5">

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>agency</label>
                                    <label>المنظمة</label>
                                </div>

                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option>
                                        {SP_Name}
                                    </option>

                                </select>
                            </div>

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Name</label>
                                    <label>الاسم</label>
                                </div>

                                <Field name="from_focal_point_id">
                                    {() => (
                                        <select
                                            disabled
                                            onChange={(e) => handleChange(e, "from_focal_point_id")}
                                            ref={focal_ref}
                                            className="w-100 input-style"
                                            style={{ marginTop: "1px" }}
                                        >
                                            <option disabled selected>
                                                Name
                                            </option>

                                            {getFData.focal_points.map((type) => (
                                                <option value={type.id}>{type.name}</option>
                                            ))}


                                        </select>
                                    )}
                                </Field>
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="from_focal_point_id" />
                                </div>

                            </div>

                            <div className="mb-3">

                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Position</label>
                                    <label>المنصب</label>
                                </div>

                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option>
                                        {fromData.position}
                                    </option>


                                </select>

                            </div>

                            <div className="row mb-lg-5 mb-3 no-gutters">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Contact</label>
                                    <label>الهاتف</label>
                                </div>
                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option>
                                        {fromData.contact}
                                    </option>


                                </select>

                            </div>

                        </div>

                    </div>

                    <div className="to-col col-lg-6 px-lg-4 px-3">
                        <h2 className="h1 reset-bold title-color form-head text-end"> الإحالة بين المنظمات</h2>
                        <p className="text-start">Referred to: specify name of agency and name of person receiving the referral (include name/phone number of the NGOs Focal Point </p>
                        <p className="text-end">إحالة إلى: تحديد اسم المنظمة واسم الشخص الذي يستلم هذه الإحالة (من ضمنها الاسم/ رقم الهاتف للشخص المسؤول) </p>

                        <div className="selects mt-5">
                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>agency</label>
                                    <label>المنظمة </label>
                                </div>

                                <Field name="to_service_provider_id">
                                    {() => (
                                        <select
                                            disabled
                                            ref={SP_ref}
                                            onChange={(e) => handleChange(e, "to_service_provider_id")}
                                            className="w-100 input-style"
                                            style={{ marginTop: "1px" }}
                                        >
                                            <option disabled selected>
                                                agency
                                            </option>

                                            {getTData.service_providers.map((type) => (
                                                <option value={type.id}>{type.user.name}</option>
                                            ))}


                                        </select>
                                    )}
                                </Field>
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="to_service_provider_id" />
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Name</label>
                                    <label>الاسم</label>
                                </div>

                                <Field name="to_focal_point_id">
                                    {() => (
                                        <select
                                            disabled
                                            onChange={(e) => handleChange(e, "to_focal_point_id")}
                                            ref={focal_ref_to}
                                            className="w-100 input-style"
                                            style={{ marginTop: "1px" }}
                                        >
                                            <option disabled selected>
                                                Name
                                            </option>

                                            {getTData.focal_points.map((type) => (
                                                <option value={type.id}>{type.name}</option>
                                            ))}


                                        </select>
                                    )}
                                </Field>
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="to_focal_point_id" />
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Position</label>
                                    <label>المنصب</label>
                                </div>

                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option>
                                        {toData.position}
                                    </option>


                                </select>

                            </div>

                            <div className="row mb-lg-5 mb-3 no-gutters">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>Contact</label>
                                    <label>الهاتف</label>
                                </div>
                                <select
                                    disabled
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                >
                                    <option>
                                        {toData.contact}
                                    </option>


                                </select>

                            </div>


                        </div>
                    </div>

                </div>

                {/* 2nd row */}
                <div className="2nd-row">

                    <div className="row mb-3">

                        <div className="col-lg-6 px-lg-4 px-3 checkbox">

                            <p className="mb-3">Consent</p>

                            <div className="labels d-flex justify-content-between w-100">
                                <label>Beneficiary has been informed of referral? </label>
                                <label>هل تم إعلام المستفيد بالإحالة؟</label>
                            </div>

                            {checkboxTemp("beneficiary_informed")}

                        </div>

                        <div className="col-lg-6 px-lg-4 px-3">

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>If no, explain</label>
                                    <label>في حالة كانت الإجابة لا.. وضح لماذا</label>
                                </div>
                                <Field
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="beneficiary_informed_explain"
                                    rows="6"
                                    placeholder="If no, explain"
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="beneficiary_informed_explain" />
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="row mb-3">

                        <div className="col-lg-6 px-lg-4 px-3 checkbox">

                            <div className="labels d-flex justify-content-between w-100">
                                <label>Beneficiary has signed consent to release information?</label>
                                <label>هل وافقت الحالة كتابيًا على الإفصاح بمعلوماته/ها؟</label>
                            </div>

                            {checkboxTemp("beneficiary_signed")}

                        </div>

                        <div className="col-lg-6 px-lg-4 px-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>If no, explain</label>
                                <label>في حالة كانت الإجابة لا.. وضح لماذا</label>
                            </div>
                            <Field
                                className="w-100 input-style"
                                as="textarea"
                                name="beneficiary_signed_explain"
                                rows="6"
                                placeholder="If no, explain"
                            />
                            <div className="mt-2 text-danger">
                                <ErrorMessage name="beneficiary_signed_explain" />
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-6 px-lg-4 px-3 checkbox">

                            <div className="labels d-flex justify-content-between w-100">
                                <label>Any contact or other restrictions?</label>
                                <label>هل هنالك أي قيود على الإتصال بالحالة أو غيرها</label>
                            </div>

                            {checkboxTemp("beneficiary_restrictions")}

                        </div>

                        <div className="col-lg-6 px-lg-4 px-3">

                            <div className="mb-3">
                                <div className="labels d-flex justify-content-between w-100">
                                    <label>If Yes, explain</label>
                                    <label>في حالة كانت الإجابة نعم.. وضح لماذا</label>
                                </div>
                                <Field
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="beneficiary_restrictions_explain"
                                    rows="6"
                                    placeholder="If Yes, explain"
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="beneficiary_restrictions_explain" />
                                </div>
                            </div>

                        </div>


                    </div>

                </div>

                {/* 3rd row */}
                <div className="row">

                    <div className="labels d-flex justify-content-between w-100 my-4 row">
                        <div className="col-lg-6 px-lg-4 px-3 mx-2">
                            <p>Beneficiary Information (All personal information is OPTIONAL depending on level of detail the beneficiary consents to disclose)</p>
                        </div>
                        <div className="col-lg-6 px-lg-4 px-3 text-end">
                            <p>معلومات الحالة  (إعطاء جميع المعلومات الشخصية هو أمر اختياري اعتمادًا على مستوى التفاصيل وموافقة الحالة على الكشف عليها)</p>
                        </div>
                    </div>

                    <div className="col-lg-6 px-4 row">

                        <div className="mb-3 col-12 px-lg-2 px-1">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Name</label>
                                <label>الاسم</label>
                            </div>

                            <input
                                disabled={isValid === true}
                                className="w-100 input-style"
                                name="beneficiary_name"
                                placeholder="Name"
                                value={beneficiaryData.beneficiary_name}
                                onChange={(e) => handleChange(e, "beneficiary_name")}
                            />
                            <div className="mt-2 text-danger">
                                <ErrorMessage name="beneficiary_name" />
                            </div>
                        </div>
                        <div className="mb-3 col-12 px-lg-2 px-1">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Address</label>
                                <label>العنوان</label>
                            </div>

                            <input
                                disabled={isValid === true}
                                className="w-100 input-style"
                                name="beneficiary_address"
                                placeholder="Address"
                                value={beneficiaryData.beneficiary_address}
                                onChange={(e) => handleChange(e, "beneficiary_address")}
                            />
                            <div className="mt-2 text-danger">
                                <ErrorMessage name="beneficiary_address" />
                            </div>
                        </div>
                        <div className="col-sm-6 mb-3 px-lg-2 px-1">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Phone</label>
                                <label>الهاتف</label>
                            </div>

                            <input
                                disabled={isValid === true}
                                className="w-100 input-style"
                                name="beneficiary_phone"
                                placeholder="Phone"
                                value={beneficiaryData.beneficiary_phone}
                                onChange={(e) => handleChange(e, "beneficiary_phone")}
                            />
                            <div className="mt-2 text-danger">
                                <ErrorMessage name="beneficiary_phone" />
                            </div>
                        </div>
                        <div className="col-sm-6 mb-3 px-lg-2 px-1">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>ID Number</label>
                                <label>رقم الهوية</label>
                            </div>

                            <input
                                disabled={isValid === true}
                                className="w-100 input-style"
                                name="id_number"
                                placeholder="ID Number"
                                value={beneficiaryData.id_number}
                                onChange={(e) => handleChange(e, "id_number")}
                            />
                        </div>


                    </div>

                    <div className="col-lg-6 px-lg-4 px-3 row no-gutters">

                        <div className="col-12 input-group mb-3 w-100 justify-content-around">

                            <div className="labels d-flex justify-content-between w-100 px-2">
                                <label>Beneficiary Code (if any)</label>
                                <label>رمز المستفيد (اذا وجد)</label>
                            </div>

                            <input disabled={isValid} className={`ben-code input-style ${isValid === false ? "red-border" : isValid === true ? "green-border" : ""}`} value={beneCode} onChange={(e) => { setBeneCode(e.target.value); setIsValid(null) }} />
                            {
                                isValid
                                    ?
                                    <div className="input-group-append">
                                        <button onClick={handleRemove} style={{ "lineHeight": "0" }} className="input-style btn btn-outline-secondary h3-responsive" type="button">{t("remove")}</button>
                                    </div>
                                    :
                                    <div className="input-group-append">
                                        <button onClick={handleCode} style={{ "lineHeight": "0" }} className="input-style btn btn-outline-secondary h3-responsive" type="button">{t("apply")}</button>
                                    </div>
                            }


                        </div>

                        <div className="col-lg-6 pr-lg-2">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>date of birth</label>
                                <label>تاريخ الميلاد</label>
                            </div>
                            <fieldset id="birthdate" className="row">

                                <div className="col">

                                    <select
                                        disabled={isValid === true}
                                        className="w-100 input-style"
                                        style={{ marginTop: "1px" }}
                                        value={beneficiaryData.birth_day}
                                        onChange={(e) => handleChange(e, "birth_day")}
                                    >
                                        {
                                            days.map(day => (
                                                <option value={day} selected={currentDay == day}>
                                                    {day}
                                                </option>
                                            ))
                                        }



                                    </select>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="birth_day" />
                                    </div>
                                </div>
                                <div className="col">

                                    <select
                                        disabled={isValid === true}
                                        className="w-100 input-style"
                                        style={{ marginTop: "1px" }}
                                        value={beneficiaryData.birth_month}
                                        onChange={(e) => handleChange(e, "birth_month")}
                                    >
                                        {
                                            monthes.map(month => (
                                                <option value={month} selected={currentMonth == month}>
                                                    {month}
                                                </option>
                                            ))
                                        }

                                    </select>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="birth_month" />
                                    </div>
                                </div>
                                <div className="col">

                                    <select
                                        disabled={isValid === true}
                                        className="w-100 input-style"
                                        style={{ marginTop: "1px" }}
                                        value={beneficiaryData.birth_year}
                                        onChange={(e) => handleChange(e, "birth_year")}
                                    >
                                        {
                                            years.map(year => (
                                                <option value={year} >
                                                    {year}
                                                </option>
                                            ))
                                        }

                                    </select>
                                    <div className="mt-2 text-danger">
                                        <ErrorMessage name="birth_year" />
                                    </div>
                                </div>

                            </fieldset>
                        </div>

                        <div className="col-lg-6 pl-lg-2">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>gender</label>
                                <label>النوع</label>
                            </div>

                            <select
                                disabled={isValid === true}
                                className="w-100 input-style"
                                value={beneficiaryData.beneficiary_gender}
                                style={{ marginTop: "1px" }}
                                onChange={(e) =>
                                    handleChange(e, "beneficiary_gender")
                                }
                            >
                                <option value="" disabled selected>
                                    gender
                                </option>

                                {genderList.map((type) => (
                                    <option value={type.id}>{type.name}</option>
                                ))}
                            </select>

                            <div className="mt-2 text-danger">
                                <ErrorMessage name="beneficiary_gender" />
                            </div>
                        </div>

                        <div className="col-lg-6 pr-lg-2">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Language</label>
                                <label>اللغة</label>
                            </div>
                            <select
                                disabled={isValid === true}
                                value={beneficiaryData.language}
                                className="w-100 input-style"
                                style={{ marginTop: "1px" }}
                                onChange={(e) =>
                                    handleChange(e, "language")
                                }
                            >
                                <option value="" disabled selected>
                                    Language
                                </option>

                                {langsList.map((type) => (
                                    <option value={type.id}>{type.name}</option>
                                ))}
                            </select>

                            <div className="mt-2 text-danger">
                                <ErrorMessage name="language" />
                            </div>
                        </div>

                        <div className="col-lg-6 pl-lg-2 mb-2">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Type of Migrant</label>
                                <label>نوع المهاجر</label>
                            </div>
                            <select
                                required
                                disabled={isValid === true}
                                className="w-100 input-style"
                                style={{ marginTop: "1px" }}
                                value={beneficiaryData.asylum_type_id}
                                onChange={(e) =>
                                    handleChange(e, "asylum_type_id")
                                }
                            >
                                <option value="" disabled>
                                    Type of Migrant
                                </option>

                                {getFData.asylum_types.map((type) => (
                                    <option value={type.id}>{type.name}</option>
                                ))}
                            </select>

                            <div className="mt-2 text-danger">
                                <ErrorMessage name="asylum_type_id" />
                            </div>
                        </div>

                        <div className="labels d-flex justify-content-between w-100 px-2">
                            <label>Asylum seeker – Yellow card or White certificate holder</label>
                            <label>باحث لجوء - بطاقة صفراء او حامل شهادة بيضاء</label>
                        </div>
                        <div className="labels d-flex justify-content-between w-100 px-2">
                            <label>Registered refugee – Blue card holder</label>
                            <label>لاجئ مسجل - حامل بطاقة زرقاء</label>
                        </div>

                    </div>

                </div>

                {/* 4th row */}
                <div className="row mb-4">

                    <div className="labels d-flex justify-content-between w-100 my-4 row">
                        <div className="col-lg-6 px-lg-4 px-3 mx-2">
                            <p>If Beneficiary Is a Minor</p>
                        </div>
                        <div className="col-lg-6 px-lg-4 px-3 text-end">
                            <p>إذا كانت الحالة قاصرًا</p>
                        </div>
                    </div>

                    <div className="col-lg-6 px-lg-4 px-3">
                        <div className="mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Name of primary caregiver</label>
                                <label>اسم الراعي</label>
                            </div>

                            <Field
                                className="w-100 input-style"
                                name="caregiver_name"
                                placeholder="Name of primary caregiver"
                            />
                            <div className="mt-2 text-danger">
                                <ErrorMessage name="caregiver_name" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Relationship to child</label>
                                <label>علاقته بالطفل</label>
                            </div>

                            <Field
                                className="w-100 input-style"
                                name="caregiver_relation"
                                placeholder="Relationship to child"
                            />
                            <div className="mt-2 text-danger">
                                <ErrorMessage name="caregiver_relation" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>Contact information for caregiver</label>
                                <label>معلومات الاتصال الخاصة بالراعي</label>
                            </div>

                            <Field
                                className="w-100 input-style"
                                name="caregiver_contact_info"
                                placeholder="Contact information for caregiver"
                            />
                            <div className="mt-2 text-danger">
                                <ErrorMessage name="caregiver_contact_info" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 px-lg-4 px-3">
                        <div className="checkbox">

                            <div className="labels d-flex justify-content-between w-100">
                                <label>Caregiver is informed of referral?</label>
                                <label>هل تم إعلام الراعي بالإحالة؟</label>
                            </div>

                            {checkboxTemp("caregiver_informed")}
                        </div>
                        <div className="mt-4 pt-3">
                            <div className="labels d-flex justify-content-between w-100">
                                <label>If no, explain</label>
                                <label>في حالة كانت الإجابة لا.. وضح لماذا</label>
                            </div>
                            <Field
                                className="w-100 input-style mt-2"
                                as="textarea"
                                name="caregiver_informed_explain"
                                rows="4"
                                placeholder="If no, explain"
                            />
                            <div className="mt-2 text-danger">
                                <ErrorMessage name="caregiver_informed_explain" />
                            </div>
                        </div>

                    </div>

                </div>

                {/* 5th row */}
                <div className="row no-gutters px-2">
                    <div className="labels d-flex justify-content-between w-100">
                        <label>Background Information/Reason for Referral: (problem description, duration, frequency, etc.)</label>
                        <label>(معلومات أساسية / سبب الإحالة: (وصف المشكلة، والمدة، والتكرار، وغيرها</label>
                    </div>

                    <Field
                        className="w-100 input-style"
                        as="textarea"
                        name="description"
                        rows="6"
                        placeholder="Background Information/Reason for Referral: (problem description, duration, frequency, etc.)"
                    />
                    <div className="mt-2 text-danger">
                        <ErrorMessage name="description" />
                    </div>
                </div>

                <button className="d-block submit-button mx-auto" onClick={handleSubmit}>{t("send")}</button>

            </Form>
        )
    }

    // handleValidation
    function handleValidation() {

        const schema = Yup.object().shape({

            from_focal_point_id: Yup.string().required(t("req")),
            to_service_provider_id: Yup.string().required(t("req")),
            to_focal_point_id: Yup.string().required(t("req")),

            beneficiary_informed: Yup.number().required(t("req")),
            beneficiary_signed: Yup.number().required(t("req")),
            beneficiary_restrictions: Yup.number().required(t("req")),

            beneficiary_informed_explain: Yup
                .string()
                .when("beneficiary_informed", {
                    is: 0,
                    then: Yup.string().required(t("req"))
                }),

            beneficiary_signed_explain: Yup
                .string()
                .when("beneficiary_signed", {
                    is: 0,
                    then: Yup.string().required(t("req"))
                }),

            beneficiary_restrictions_explain: Yup
                .string()
                .when("beneficiary_restrictions", {
                    is: 1,
                    then: Yup.string().required(t("req"))
                }),


            beneficiary_name: Yup.string()
                .when("beneficiary_code", {
                    is: (e) => !e,
                    then: Yup.string().required(t("req"))
                }),
            beneficiary_phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum"))
                .when("beneficiary_code", {
                    is: (e) => !e,
                    then: Yup.string().required(t("req"))
                }),
            beneficiary_address: Yup.string()
                .when("beneficiary_code", {
                    is: (e) => !e,
                    then: Yup.string().required(t("req"))
                }),
            birth_year: Yup.number()
                .when("beneficiary_code", {
                    is: (e) => !e,
                    then: Yup.number().required(t("req"))
                }),
            birth_month: Yup.string()
                .when("beneficiary_code", {
                    is: (e) => !e,
                    then: Yup.string().required(t("req"))
                }),
            birth_day: Yup.number()
                .when("beneficiary_code", {
                    is: (e) => !e,
                    then: Yup.number().required(t("req"))
                }),
            beneficiary_gender: Yup.string()
                .when("beneficiary_code", {
                    is: (e) => !e,
                    then: Yup.string().required(t("req"))
                }),
            language: Yup.string()
                .when("beneficiary_code", {
                    is: (e) => !e,
                    then: Yup.string().required(t("req"))
                }),
            asylum_type_id: Yup.string()
                .when("beneficiary_code", {
                    is: (e) => !e,
                    then: Yup.string().required(t("req"))
                }),

            caregiver_informed: Yup.number(),

            caregiver_informed_explain: Yup.string()
                .when("caregiver_informed", {
                    is: 0,
                    then: Yup.string().required(t("req"))
                }),

            description: Yup.string().required(t("req")),
        }, [
            "beneficiary_name",
            "beneficiary_code",
            "beneficiary_phone",
            "beneficiary_address",
            "birth_year",
            "birth_month",
            "birth_day",
            "beneficiary_gender",
            "language",
            "asylum_type_id"
        ])
        return schema;
    }

    return (
        <div className="referralForm">
            <Toast position="top-center" ref={toast} />
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }
            <div className="min-H container-fluid my-5 SP-Dash px-5">

                <Formik validationSchema={handleValidation} innerRef={formRef} initialValues={initialValues} onSubmit={handleSubmit}>
                    {formBody}
                </Formik>

            </div>
        </div>
    )

}

export default ReferralForm
