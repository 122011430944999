import * as Yup from "yup";
import React, { useState } from "react";
import { Toast } from 'primereact/toast';
import { useTranslation } from "react-i18next";
import { Formik, Field, ErrorMessage } from "formik";
import axiosInstance from "../../services/axiosInstance";
import Location from '../../../components/formCompoment/Location';

function Locations({ serviceProvider }) {

  // language
  const Lang = localStorage.getItem("i18nextLng");

  const { t } = useTranslation();

  const formRef = React.useRef();
  const toast = React.useRef(null);
  // loading state
  const [isLoading, setIsLoading] = React.useState(true);

  const [initialValues, setInitialValues] = React.useState({
    'locations': serviceProvider.locations.map((location) => {
      return {
        id: location.id,
        street: location.street,
        building: location.building,
        floor: location.floor,
        governorate_id: location.governorate.id,
        district_id: location.district.id,
        governorate: location.governorate,
        district: location.district,
        lat: location.lat,
        long: location.long,
      }
    })
  })


  const [governsList, setGovernsList] = React.useState([
    {
      id: "",
      name: "",
    },
  ]);

  function handleSubmit(values) {

    setIsLoading(true)
    axiosInstance().post("/service-provider/profile/second", values)
      .then(() => {
        toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false);
        toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
      });
  }

  React.useEffect(() => {

    axiosInstance().get("/governorates", { 'headers': { 'locale': Lang } })
      .then((response) => {
        setGovernsList(response.data.data);
        setIsLoading(false)
      })
  }, [Lang]);

  /**
     * removeLocation
     * call from Location component props.onRemove
     * @param {integer} i index of location section
     * @return void
     */
  function removeLocation(i) {
    let values = { ...initialValues };
    values.locations.splice(i, 1);

    setInitialValues(values)
    formRef.current.setFieldTouched('locations', true)
  }

  function addLocation() {
    if (!("locations" in formRef.current.errors)) {
      let values = { ...initialValues };
      values.locations.push({});

      setInitialValues(values)
      formRef.current.setFieldTouched('locations', true)
    }
  }

  // handleValidation
  function handleValidation() {
    const schema = Yup.object().shape({

      locations: Yup.array()
        .of(
          Yup.object().shape({
            governorate_id: Yup.string().required(t("required")),
            district_id: Yup.string().required(t("required")),

            street: Yup.string().required(t("required")),
            building: Yup.string().required(t("required")),
            // lat: Yup.string().required(t("required")),
            // long: Yup.string().required(t("required")),
          })

        )

    })
    return schema;
  }

  function formBody({ handleSubmit }) {
    return (
      <form className="auth-form step_form" onSubmit={handleSubmit}>

        {/* LOCATIONS */}
        <div className="row">
          <div className="col-12">
            <p className="sub-title h3 mb-4">{t("location.locations")}</p>
          </div>
          {
            initialValues.locations.map((location, i) =>
              <Location
                key={i}
                i={i}
                arrayName='locations'
                governsList={governsList}
                location={location}
                onRemove={removeLocation}
              ></Location>
            )
          }

        </div>

        <div className="row justify-content-end px-5">

          <button type="button" onClick={addLocation} className="mx-auto mx-sm-0 mb-3 mb-sm-0 reset_button px-4 main-color text-capitalize d-inline-block m-0">{t("addAnother")}</button>

          <button
            type="submit"
            className="next-button main-buttonII px-5 text-capitalize d-inline-block mx-auto mx-sm-0"
          >
            {t("save")}
          </button>
        </div>
      </form>
    );
  }

  return (
    <div className="register p-relative">
      <Toast position="top-center" ref={toast} />
      {
        isLoading
        &&
        <div className="text-center spinner-container">
          <i className="fas fa-circle-notch fa-spin fa-4x"></i>
        </div>
      }
      <Formik enableReinitialize innerRef={formRef} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={handleValidation}>
        {formBody}
      </Formik>
    </div>
  );
}

export default Locations;
