import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../../services/axiosInstance';
import Pager from '../../../../components/Pager';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function Statistics() {

    const { t } = useTranslation();

    const toast = React.useRef(null);

    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const [SPList, setSPList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [eventsList, setEventsList] = useState([]);

    const [globalInfo, setGlobalInfo] = useState({});


    useEffect(() => {

        axiosInstance().get("/stats", { 'headers': { 'locale': Lang } })
            .then((res) => {
                setGlobalInfo(res.data.data.stats);
                setTotal(res.data.data.pageInfo.total);
            })
            .catch((error) => {
                console.log(error);
            })

        axiosInstance().get("/stats/service-providers", { 'headers': { 'locale': Lang } })
            .then((res) => {
                setSPList(res.data.data.collection);
                setTotal(res.data.data.pageInfo.total);
            })
            .catch((error) => {
                console.log(error);
            })

        // axiosInstance().get("/stats/services", { 'headers': { 'locale': Lang } })
        //     .then((res) => {
        //         setServicesList(res.data.data.collection);
        //         setTotal2(res.data.data.pageInfo.total);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     })

        // axiosInstance().get("/stats/events", { 'headers': { 'locale': Lang } })
        //     .then((res) => {
        //         setIsLoading(false);
        //         setEventsList(res.data.data.collection);
        //         setTotal3(res.data.data.pageInfo.total);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //         setIsLoading(false)
        //     })

    }, [Lang]);


    // pager code
    const [Total, setTotal] = React.useState(0);
    const [Total2, setTotal2] = React.useState(0);
    const [Total3, setTotal3] = React.useState(0);

    const onBasicPageChange = (event) => {

        setIsLoading(true);
        axiosInstance().get(`/stats/service-providers?page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
            .then(res => {
                setSPList(res.data.data.collection);
                setTotal(res.data.data.pageInfo.total);
                setIsLoading(false);

            }).catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    }

    // const onBasicPageChange2 = (event) => {
    //     setIsLoading(true);
    //     axiosInstance().get(`/stats/services?page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
    //         .then(res => {
    //             setServicesList(res.data.data.collection)
    //             setTotal2(res.data.data.pageInfo.total);
    //             setIsLoading(false);

    //         }).catch(err => {
    //             console.log(err)
    //             setIsLoading(false)
    //         })
    // }

    // const onBasicPageChange3 = (event) => {
    //     setIsLoading(true);
    //     axiosInstance().get(`/stats/events?page=${event.page + 1}&perPage=${event.rows}`, { 'headers': { 'locale': Lang } })
    //         .then(res => {
    //             setIsLoading(true);
    //             setEventsList(res.data.data.collection)
    //             setTotal3(res.data.data.pageInfo.total);
    //             setIsLoading(false);
    //         }).catch(err => {
    //             console.log(err)
    //             setIsLoading(false)
    //         })
    // }

    function onIndexTemplateL(data) {
        return (
            <div>
                <img src={data.logo || "/img/example.png"} alt="logo" className="smallPic img-responsive rounded-circle" />
                <span className='d-inline-block mx-2 table-title'>{data.nickname}</span>
            </div>
        )
    }

    function onIndexTemplateS(data) {
        return (
            <div>
                <img src={data.featured_image || "/img/example.png"} alt="logo" className="smallPic img-responsive rounded-circle" />
                <span className='d-inline-block mx-2 table-title'>{data.title}</span>
            </div>
        )
    }

    return (
        <div className="statistics">
            <Toast position="top-center" ref={toast} />
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }

            <div className="min-H pt-5 SP-Dash px-4">

                <div className="tabs">
                    <div className="row mb-5">

                        <div className="col-3">
                            <div className="global-box px-3">
                                {globalInfo.migrant_count}
                                <p>{t("migrant")}</p>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="global-box px-3">
                                {globalInfo.service_provider_count}
                                <p>{t("admin.stats.SP.title")}</p>
                            </div>
                        </div>

                        {/* <div className="col-3">
                            <div className="global-box px-3">
                                {globalInfo.service_count}
                                <p>{t("admin.stats.services.title")}</p>
                            </div>
                        </div> */}

                        {/* <div className="col-3">
                            <div className="global-box px-3">
                                {globalInfo.event_count}
                                <p>{t("admin.stats.events.title")}</p>
                            </div>
                        </div> */}

                    </div>
                </div>

                <div className="SP-table">
                    <h4 className="reset-bold mb-4 text-capitalize">{t("admin.stats.SP.title")}</h4>
                    <div className="list">
                        <DataTable emptyMessage={t("referral.emptyMsg")} value={SPList} responsiveLayout="scroll">

                            <Column field="featured_image" body={onIndexTemplateL} />

                            <Column field="services_count" header={t("admin.stats.SP.head_1")} />
                            <Column field="events_count" header={t("admin.stats.SP.head_2")} />
                            <Column field="total_referrals" header={t("admin.stats.SP.head_5")} />
                            <Column field="referrals_from_count" header={t("admin.stats.SP.head_3")} />
                            <Column field="referrals_to_count" header={t("admin.stats.SP.head_4")} />

                        </DataTable>
                    </div>

                    {SPList.length > 0 && <Pager total={Total} onPageChange={onBasicPageChange} />}

                </div>

                {/* <div className="services-table">
                    <h4 className="reset-bold mb-4 text-capitalize">{t("admin.stats.services.title")}</h4>
                    <div className="list">
                        <DataTable emptyMessage={t("referral.emptyMsg")} value={servicesList} responsiveLayout="scroll">

                            <Column field="featured_image" body={onIndexTemplateS} />

                            <Column field="applicants_count" header={t("admin.stats.services.head_2")} />
                            <Column field="done_applicants_count" header={t("admin.stats.services.head_3")} />
                            <Column field="pending_applicants_count" header={t("admin.stats.services.head_4")} />

                        </DataTable>
                    </div>

                    {servicesList.length > 0 && <Pager total={Total2} onPageChange={onBasicPageChange2} />}

                </div>

                <div className="events-table">
                    <h4 className="reset-bold mb-4 text-capitalize">{t("admin.stats.events.title")}</h4>
                    <div className="list">
                        <DataTable emptyMessage={t("referral.emptyMsg")} value={eventsList} responsiveLayout="scroll">

                            <Column field="featured_image" body={onIndexTemplateS} />

                            <Column field="applicants_count" header={t("admin.stats.services.head_2")} />
                            <Column field="done_applicants_count" header={t("admin.stats.services.head_3")} />
                            <Column field="pending_applicants_count" header={t("admin.stats.services.head_4")} />

                        </DataTable>
                    </div>
                </div>

                {eventsList.length > 0 && <Pager total={Total3} onPageChange={onBasicPageChange3} />} */}

            </div>

        </div >
    );
}

export default Statistics
