import React from 'react';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import Step_one from './Step_one';
import Step_two from './Step_two';
import Final_step from './Final_step';
import Step_three from './Step_three';

function SP_Register() {


    const authReducer = useSelector((state) => state.authReducer);

    function StepsSwitch(authReducer) {

        switch (authReducer.index) {

            case 1:
                return <Step_one />

            case 2:
                return <Step_two />

            case 3:
                return <Step_three />

            case 4:
                return <Final_step />

            default:
                break;
        }

    }

    return (
        <div className="register">
            {StepsSwitch(authReducer)}
        </div >
    )
}

export default SP_Register;
