import { Spinner } from "react-bootstrap";

function Loading() {
    return (
        <div className="w-100 text-center" style={{ "height": "100vh", "lineHeight": "100vh" }}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default Loading;