import React from 'react';
import { useParams } from 'react-router';
import Map from "../../components/Map";
import axiosInstance from "../services/axiosInstance";
import EventCard from '../../components/Cards/EventCard';

import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';

import ServiceCard from '../../components/Cards/ServiceCard';

function ServiceProviderPage({ isAdmin }) {

    const { id } = useParams();
    const toast = React.useRef(null);

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);
    const { t } = useTranslation();

    const [servicesList, setServicesList] = React.useState([]);
    const [eventsList, setEventsList] = React.useState([]);

    // const [eventList, setEventList] = React.useState([]);
    const [serviceProvider, setServiceProvider] = React.useState({
        name: '',
        description: '',
        logo: '',
        service_categories: [],
        phone: '',
        email: '',
        website: ''
    });


    const Lang = localStorage.getItem("i18nextLng");

    React.useEffect(() => {
        setIsLoading(true);
    
        axiosInstance()
            .get(`/service-provider?serviceCategoryId=${id}`, { headers: { locale: Lang } })
            .then((response) => {
                setServiceProvider(response.data.data.collection); // Assuming the API returns this structure
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('API error:', error);
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to load service provider data' });
            });
    }, [id, Lang]);


    return (
        <>
            <div className="position-relative">
                <Toast position="top-center" ref={toast} />

                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }

                <div className="min-H container-fluid custom-container my-5 px-5">
                    <div></div>
                    <div className="row">
                        <div className="col-md-3">
                            {/* service image */}
                            <img alt="service-image" className="img-fluid serv_img w-100" src={serviceProvider.logo || "/img/example.png"} />



                        </div>
                        {/* service details */}
                        <div className="col-md-9">

                            <p className="section-title mt-4 mt-md-0">{serviceProvider.name}</p>
                            {/* service categories */}

                            <div className="noMarginParent">
                                {serviceProvider && serviceProvider.service_categories &&
                                    serviceProvider.service_categories.map((serviceCategory, i) =>
                                        <span className="badge badge-pill badge-primary service-category-small py-2 px-3 mx-2 my-2" key={i}>{serviceCategory.name}</span>
                                    )
                                }
                            </div>

                            <p className="mt-5 description">{serviceProvider.description}</p>

                            <label className="font-weight-bold d-block mt-4 mb-2">{t("contact")}</label>
                            {serviceProvider.phone && <p className="text-primary"><i className="pi pi-phone"></i> {serviceProvider.phone}</p>}
                            {serviceProvider.email && <p className="text-primary"><i className="pi pi-envelope"></i> {serviceProvider.email}</p>}
                            {serviceProvider.website && <p className="text-primary"><i className="pi pi-globe"></i> <a target="_blank" href={serviceProvider.website}>{serviceProvider.website}</a></p>}

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default ServiceProviderPage