import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import Pager from '../../../../components/Pager';
import { NavLink } from 'react-router-dom';

import TagCard from '../../../../components/Cards/TagCard';
import ServiceProviderCard from '../../../../components/Cards/TagCard';
//import axiosInstance from '../../../services/';
import axiosInstance from "../../../services/axiosInstance";
import { useParams } from 'react-router';
import ServiceCategoryList from '../../../../components/Tags';

function News({ isAdmin }) {

    // loading state
    const [isLoading, setIsLoading] = React.useState(true);

    const { t } = useTranslation();
   
    const [filterValues, setFilterValues] = useState([]);
    const [newsList, setNewsList] = React.useState([]);
    const [serviceProviderList, setServiceProviderList] = React.useState([]);
    const [totalServiceProviderList, setTotalServiceProviderList] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [basicFirst, setBasicFirst] = React.useState(0);
    const [perPage, setPerPage] = React.useState(10);
    const [fitlerValues, setFitlerValues] = React.useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(1);  // Track selected category
    const { id } = useParams();

    const [total, setTotal] = React.useState(0);

    const Lang = localStorage.getItem("i18nextLng");
    // Function to load service providers for a specific category and page
  function loadServiceProviderList(params = {}) {
    params.perPage = params.perPage || perPage;
    params.page = params.page || page;
    params = { ...params, ...filterValues };

    setIsLoading(true);

    // Only make the request if a category is selected
    // if (selectedCategoryId) {
    //   axiosInstance()
    //     .get(`/service-provider?serviceCategoryId=${selectedCategoryId}`, {
    //       params,
    //       headers: { 'locale': Lang },
    //     })
    //     .then((response) => {
    //       setServiceProviderList(response.data.data.collection);
    //       setTotalServiceProviderList(response.data.data.pageInfo.total);
    //       setIsLoading(false);
    //     })
    //     .catch((error) => {
    //       console.error("Error loading service providers:", error);
    //       setIsLoading(false);
    //     });
    // } else {
    //   setIsLoading(false);
    // }
  }
  const handleCategorySelect = (id) => {
    setSelectedCategoryId(id); // Update selected category ID
  };


    const onBasicPageChange = (event) => {
        let eventPage = event.page + 1;

        setPage(eventPage);
        setBasicFirst(event.first);
        setPerPage(event.rows)
        loadServiceProviderList({ perPage: event.rows, page: eventPage })
    }
    function loadServiceProviderList(params = {}) {
        params.perPage = params.perPage || perPage;
        params.page = params.page || page;
        params = { ...params, ...fitlerValues }

        setIsLoading(true)
        axiosInstance().get(`/service-provider?serviceCategoryId=${selectedCategoryId}`, { params, 'headers': { 'locale': Lang } })
            .then((response) => populateServiceProviderList(response))

    }

    function populateServiceProviderList(response) {
        setIsLoading(false)
        setServiceProviderList(response.data.data.collection)
        setTotalServiceProviderList(response.data.data.pageInfo.total);
    }
    useEffect(() => {
        loadServiceProviderList(); // Trigger fetching based on initial selected category
      }, [Lang, selectedCategoryId]); 
      

    React.useEffect(() => {

        setIsLoading(true)

        axios(`/service-provider?serviceCategoryId=${selectedCategoryId}`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setTotal(response.data.data.pageInfo.total);
                setNewsList(response.data.data.collection);
                setIsLoading(false)
            })
            .catch(() => {
                setIsLoading(false)
            })

    }, [Lang])
    return (
        <div className="news">
            <div className="position-relative">
                {
                    isLoading
                    &&
                    <div className="text-center spinner-container">
                        <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                    </div>
                }
                <div className={isAdmin ? "min-H pt-5 SP-Dash px-4" : "min-H container-fluid my-5 SP-Dash px-5"}>

                    <div className="labels d-flex justify-content-between w-100 align-items-center mb-5">
                        <span className="h2 reset-bold text-capitalize main-title-res">{t("service_providers")}</span>
                        {isAdmin && <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to="/admin/news/add">{t("admin.addNew")}</NavLink></button>}
                    </div>
                    <ServiceCategoryList onCategorySelect={handleCategorySelect} />
                     <div className="cards_list service_cards row">
                       {serviceProviderList.length > 0 ? (
                          serviceProviderList.map((category) => (
                             <div className="col-lg-3 col-md-4 col-sm-6 gallery-img-card mb-4" key={category.id}>
                                <ServiceProviderCard serviceProvider={category} />
                             </div>
                        ))
                        ) : (
                      <p className="lead mx-3 no-cont">{t("noContent")}</p>
                      )}
                  </div>
                </div >
            </div>
            <Pager total={total} onPageChange={onBasicPageChange} className={`${serviceProviderList.length ? '' : 'd-none'}`} />
        </div>
    )
}

export default News
