
import React, { useState, useEffect } from 'react';
import { Formik, Field, ErrorMessage, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../../services/axiosInstance';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import { FileUpload } from 'primereact/fileupload';
import FormikControl from '../../../../components/formCompoment/FormikControl';
import { Dropdown } from 'react-bootstrap';

function News_control({ isNew }) {

    const { t } = useTranslation();
    const { id } = useParams();
    const [categories, setCategories] = useState([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState(null);
  const handleCategoryChange = (e, setFieldValue) => {
    // Get the category name and map it to the category id using the selectedCategory function
    const categoryId = selectedCategory(e.value); // Get category ID based on selected name
    setFieldValue("services", categoryId); // Update Formik value
    setSelectedCategoryValue(categoryId); // Optionally, update local state to reflect selected category
  };

    const [initialValues, setInitialValues] = useState({

        featured_image: "/img/example.png",

        name: "",
        email: "",
        logo:[],
        phone:"",
        website:"",
        location:"",
        description:"",
        working_hours:"",
        services:"",
        asylum_types:"",
        local_government_registry:"",


        images: [],
        videos: []

    });
    const selectedCategory = (category) => {
        switch (category) {
         case "CP":
            return 1;
          case "GBV":
            return 2;
          case "Cash Assistance":
            return 3;
          case "Health":
            return 4;
          case "MHPSS":
            return 5;
          case "Housing":
            return 6;
          case "Education":
            return 7;
          case "Livelihoods":
            return 8;
          case "Legal Assistance":
            return 9;
          case "Organizational Map":
            return 10;
          default:
            return 1;
        }
      };

    // upload setting
    const noFile = null;
    const inputFile = React.useRef(null);
    const [fileName, setFileName] = React.useState(noFile);
    const btnClicked = () => {
        inputFile.current.click();
    };

    const toast = React.useRef(null);

    // language
    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = useState(true);

    // langs options
    const [langsList, setLangsList] = useState([
        {
            id: "en",
            name: "English",
        },
        {
            id: "ar",
            name: "العربية",
        }
    ]);

    useEffect(() => {

        if (!isNew) {

            axiosInstance()
                .get(`/service-providers/${id}`)
                .then((response) => {
                    setInitialValues(response.data.data);
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    // toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                });

        } else {
            setIsLoading(false)
        }


    }, [Lang]);


    const [contentLang, setContentLang] = React.useState("en");

    function handleChange(e, name) {
        setContentLang(e.target.value)
    }

    function handleSubmit(values) {

        setIsLoading(true)

        let newVals = { ...values };

        for (const [key, val] of Object.entries(newVals)) {
            if (key == "logo" && val == []) {
                delete newVals[key];
            }
            if (key == "videos" && val == []) {
                delete newVals[key];
            }
            if (key == "links" && val == [] || !val) {
                delete newVals[key];
            }
            if (key == "featured_image" && typeof val == "string") {
                delete newVals[key];
            }
        }

        // create formdata
        let formData = new FormData();

        for (const [key, val] of Object.entries(newVals)) {
            if (key == "logo") {
                val.map((item, i) => {
                    if (item.id) {
                        item.id && formData.append(`logo[${i}][id]`, item.id);
                    } else {
                        formData.append(`logo[${i}][logo]`, item.image);
                    }
                })
            } else if (key == "videos") {
                val.map((item, i) => {

                    if (item.id) {
                        item.id && formData.append(`videos[${i}][id]`, item.id);
                    } else {
                        formData.append(`videos[${i}][video]`, item.video);
                    }
                })
            } else if (key == "links") {
                val.map((item, i) => {
                    item
                        &&
                        formData.append(`links[${i}]`, item);
                })
            } else {
                formData.append(key, val);
            }
        }

        !isNew
            ?
            axiosInstance()
                .post(`/service-providers/${id}`, formData)
                .then(() => {
                    setIsLoading(false)
                    toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                    setTimeout(() => {
                        window.location.href = `/admin/service-providers/${id}`
                    }, 1500);

                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                })
            :
            axiosInstance()
                .post(`/service-providers`, formData, { 'headers': { 'locale': contentLang } })
                .then((response) => {
                    setIsLoading(false)
                    toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
                    setTimeout(() => {
                        window.location.href = `/admin/service-providers/${response.data.data.id}`
                    }, 1500);

                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false)
                    toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
                });
    }

    function FormBody({ handleSubmit, values }) {

        return (

            <Form className="auth-form step_form" onSubmit={handleSubmit}>

                <div className="row justify-content-between no-gutters mb-5">

                    <h3 className="alreadyReg d-flex align-items-center">{isNew ? t("addN") : t("editN")}</h3>
                    <div className="buttons align-items-center d-flex">

                        {
                            isNew
                                ?
                                <div className="d-inline-block mx-4">
                                    <select
                                        className="w-100 input-style"
                                        style={{ marginTop: "1px" }}
                                        onChange={(e) =>
                                            handleChange(e, "language", "language")
                                        }
                                    >

                                        {langsList.map((type) => (
                                            <option value={type.id}>{type.name}</option>
                                        ))}
                                    </select>
                                </div>
                                :
                                ""
                        }

                        <button type="submit" className="submit-button my-auto text-capitalize d-inline-block">{t("submit")}</button>
                        {
                            !isNew
                                ?
                                <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to={`/admin/service-providers/${id}`}>{t("cancel")}</NavLink></button>
                                :
                                <button type="button" className="submit-button my-auto mx-3 custom text-capitalize d-inline-block"><NavLink className="white" to={`/admin/news`}>{t("cancel")}</NavLink></button>
                        }
                    </div>

                </div>

                <div className="row">
                    <div className="col-3 row" style={{ "alignContent": "baseline" }}>

                        <div className="col-12 p-2 id-photo" style={{ paddingInlineEnd: "20px" }}>

                            {/* FEATURED IMAGE */}
                            <img alt="featured-image" className="img-fluid mt-3 w-100 serv_img" style={{ "maxHeight": "300px" }} src={`${values.featured_image && typeof values.featured_image !== "string" ? URL.createObjectURL(values.featured_image) : values.featured_image}`} />

                            <Field onClick={btnClicked} className="field" name="featured_image">
                                {({ form }) => (
                                    <>
                                        <FileUpload
                                            onSelect={(event) => {
                                                let file = event.files[0];
                                                let reader = new FileReader();
                                                if (file && file.type.match('image.*')) {
                                                    setFileName(file.name);
                                                    form.setFieldValue("featured_image", file);
                                                }
                                            }}
                                            ref={inputFile}
                                            accept="logo/*"
                                            autocomplete={false}
                                        />
                                        <input
                                            type="logo"
                                            className="d-none"
                                            ref={inputFile}
                                            onChange={(event) => {
                                                let file = event.currentTarget.files[0];
                                                let reader = new FileReader();

                                                if (file && file.type.match('image.*')) {
                                                    setFileName(file.name);
                                                    form.setFieldValue("featured_image", file);
                                                }

                                            }}
                                        />
                                    </>
                                )}
                            </Field>

                            <Field
                                onClick={btnClicked}
                                name="featured_image"
                                placeholder={fileName || t("upImg")}
                                value={fileName || ""}
                                icon="fas fa-file-upload"
                                className="w-100 input-style cursor-none upload"
                            />

                            <div className="mt-2 text-danger">
                                <ErrorMessage name={"featured_image"} />
                            </div>
                        </div>

                        {/* MEDIA PREVIEW */}

                    </div>

                    <div className="col-9">
                        <div className="row">
                            <div className="col-12 mb-4">
                                <label>{t("name")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="name"
                                    placeholder={t("name")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="name" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                            <label>{t("category")}</label>
                                  <Field name="services">
                                     {({ field, form }) => (
                                        <Dropdown
                                          {...field}
                                            value={selectedCategory} // Value of the selected category
                                            options={categories.map(category => ({ label: category.id, value: category.id }))} // Options to be populated from API or static data
                                            onChange={(e) => {
                                            form.setFieldValue(field.name, e.value); // Update Formik value on selection
                                            handleCategoryChange(e); // Update local state
                                             }}
                                             optionLabel="name" // Display category name in the dropdown
                                             optionValue="id" // Use category id as value for selection
                                              placeholder={t("selectCategory")}
                                             className="w-100 input-style"
                                          />
                                      )}
                                   </Field>
                                {/* <label>{t("catogray")}</label>
                                <Dropdown
                                    className="w-100 input-style"
                                    name="services"
                                    placeholder={t("catogray")}
                                /> */}
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="name" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("email")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="email"
                                    placeholder={t("email")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="email" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("phone")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="phone"
                                    placeholder={t("phone")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="phone" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("office address ")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="office address "
                                    placeholder={t("office address ")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="office address " />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("office location link ")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="office location link  "
                                    placeholder={t("office location link ")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="office location link  " />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("website link")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="website link"
                                    placeholder={t("website link")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="website link " />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("social media link")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="social media link"
                                    placeholder={t("social media link")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="social media link" />
                                </div>
                            </div>
                            <div className="col-12 mb-4">
                                <label>{t("working hours")}</label>
                                <Field
                                    className="w-100 input-style"
                                    name="working hours"
                                    placeholder={t("working hours")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="working hours" />
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <label>
                                    {t("target segments")}
                                </label>
                                <Field
                                    rows={9}
                                    required
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="target segments"
                                    placeholder={t("target segments")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="target segments" />
                                </div>
                            </div>
                            <div className="col-12">
                                <label>
                                    {t("service provided")}
                                </label>
                                <Field
                                    rows={9}
                                    required
                                    className="w-100 input-style"
                                    as="textarea"
                                    name="service provided"
                                    placeholder={t("service provided")}
                                />
                                <div className="mt-2 text-danger">
                                    <ErrorMessage name="service provided" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </Form >
        )
    }

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({

            title: Yup.string().required(t("req")),
            description: Yup.string().required(t("req")),
            featured_image: Yup.string().test("file", t("req"), function (str) {
                return str == "/img/example.png" ? false : true
            })

        })
        return schema;
    }

    return (
        <div className="position-relative">
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }

            <div className="min-H pt-5 SP-Dash px-4">

                < Toast position="top-center" ref={toast} />

                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}

                    // validation schema
                    validationSchema={handleValidation}
                >

                    {FormBody}

                </Formik>

            </div>
        </div>
    )
}

export default News_control
