import React from 'react';
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import { TabView, TabPanel } from 'primereact/tabview';
import { FileUpload } from 'primereact/fileupload';
import SP_Register from './SP_Register';
import M_Register from './M_Register';
import { Route, Switch } from "react-router-dom";

function Register() {

    const [activeIndex, setActiveIndex] = React.useState(null);

    const { t } = useTranslation();

    function tabsTemp() {

        return (

            <div className={activeIndex !== null ? "d-none auth-form tabs-container" : "auth-form tabs-container"}>

                <div className="title text-center">{t("SYC")}</div>

                <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>

                    <TabPanel header={t("migrant")} />

                    <TabPanel header={t("SP")} />

                </TabView>

                <a href="/login" className="alreadyReg d-flex">{t("auth.register.already")}</a>

            </div>
        )
    }

    function handleTabChange(e) {
        e.index
            ?
            window.location.href = "register/service_provider"
            :
            window.location.href = "register/migrant"
    }

    return (
        <div className="register">

            <Switch>

                <Route exact path="/register" component={tabsTemp} />

                <Route exact path="/register/service_provider" component={SP_Register} />

                <Route exact path="/register/migrant" component={M_Register} />

            </Switch>


        </div >
    )
}

export default Register;
