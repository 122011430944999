import React, { useState, useEffect } from 'react';
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Toast } from 'primereact/toast';
import { updateAuth } from '../../store/actions/auth';
import axiosInstance from '../services/axiosInstance';

function MigrantAcc() {

    const { t } = useTranslation();

    const [initialValues, setInitialValues] = useState({

        name: "",
        username: "",
        email: "",
        password: "",
        password_confirmation: "",
        id_number: "",

        phone: "",
        additional_phone: "",
        country_id: "",

        age: "",
        asylum_type_id: "",
        gender: "",

        secret: 0

    });

    // make the actions on the state
    const dispatch = useDispatch();

    const toast = React.useRef(null);

    // language
    const Lang = localStorage.getItem("i18nextLng");

    // loading state
    const [isLoading, setIsLoading] = useState(true);

    const [countriesList, setCountriesList] = useState([]);

    const [asylumList, setAsylumList] = useState([
        {
            id: "",
            name: "",
        },
    ]);

    const [genderList, setGenderList] = useState([
        {
            id: "male",
            name: t("male"),
        },
        {
            id: "female",
            name: t("female"),
        },
        {
            id: "other",
            name: t("other"),
        }
    ]);


    useEffect(() => {

        axios("/countries", { 'headers': { 'locale': Lang } })
            .then((response) => {
                setCountriesList(response.data.data);
                document.querySelectorAll("select")[1].disabled = false;
            })
            .catch((error) => {
                console.log(error);
            });

        axios(`/asylum-types`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setAsylumList(response.data.data);
                // setIsLoading(false);
                document.querySelectorAll("select")[2].disabled = false;
            })
            .catch(() => {
                // setIsLoading(false)
            });

        axiosInstance()
            .get("/migrant/profile")
            .then((response) => {

                let newObj = {}
                let data = response.data.data

                for (const [key, val] of Object.entries(data)) {
                    if (key == "user") {
                        for (const [key, val] of Object.entries(data.user)) {
                            newObj[key] = val
                        }
                    } else {
                        newObj[key] = val
                    }
                }

                newObj["country_id"] = data.country.id
                newObj["asylum_type_id"] = data.asylum_type.id
                newObj["password"] = ""

                setInitialValues(newObj);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
                // toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
            });


    }, [Lang]);

    const selfID = useSelector((state) => state.authReducer && state.authReducer.id_number && state.authReducer.id_number);


    function handleSubmit(values) {

        setIsLoading(true)

        for (const [key, val] of Object.entries(values)) {
            if (key == "password" && val == "") {
                delete values[key];
            }
            if (key == "password_confirmation" && val == "") {
                delete values[key];
            }
        }

        if (selfID == values.id_number) {
            delete values["id_number"];
        }

        axiosInstance()
            .post("/migrant/profile", values)
            .then((response) => {
                setIsLoading(false)
                dispatch(updateAuth(response.data.data));
                toast.current.show({ severity: 'success', summary: t("success"), detail: t("successMsg"), life: 2000 });
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
                toast.current.show({ severity: 'warn', summary: t("warn"), detail: Object.values(err.response.data.errors && err.response.data.errors) })
            });
    }

    function handleChange(e, form, name) {
        form.setFieldValue(name, e.target.value)
    }

    function FormBody({ handleSubmit, values }) {

        return (

            <Form className="auth-form step_form" onSubmit={handleSubmit}>

                <div className="row justify-content-between no-gutters mb-5">

                    <h3 className="alreadyReg mig-acc d-flex align-items-center">{t("editP")}</h3>
                    <button type="submit" className="next-button main-buttonII px-5 text-capitalize d-inline-block">{t("submit")}</button>

                </div>

                {/* first */}
                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-md-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.fullName")}</label>
                        <Field

                            className="w-100 input-style"
                            name="name"
                            placeholder={t("auth.register.step_1.fullName")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="name" />
                        </div>

                    </div>

                    <div className="col-md-4 mb-md-0 mb-3 px-md-3">
                        <label>{t("auth.login.user")}</label>
                        <Field

                            className="w-100 input-style"
                            name="username"
                            placeholder={t("auth.login.user")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="username" />
                        </div>
                    </div>

                    <div className="col-md-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.emailAddressOp")}</label>
                        <Field
                            className="w-100 input-style"
                            type="email"
                            name="email"
                            placeholder={t("auth.register.step_1.emailAddressOp")}
                        />
                    </div>

                </div>

                {/* second */}
                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-md-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.password")}</label>
                        <Field
                            autocomplete="new-password"
                            className="w-100 input-style"
                            type="password"
                            name="password"
                            placeholder={t("auth.register.step_1.password")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="password" />
                        </div>
                    </div>

                    <div className="col-md-4 mb-md-0 mb-3 px-md-3">
                        <label>{t("auth.register.step_1.confirmPass")}</label>
                        <Field

                            className="w-100 input-style"
                            type="password"
                            name="password_confirmation"
                            placeholder={t("auth.register.step_1.confirmPass")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="password_confirmation" />
                        </div>
                    </div>

                    <div className="col-md-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.ID")}</label>
                        <Field

                            className="w-100 input-style"
                            name="id_number"
                            placeholder={t("auth.register.step_1.ID")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="id_number" />
                        </div>
                    </div>

                </div>

                {/* third */}
                <div className="row mb-0 mb-md-4 no-gutters">

                    <div className="col-md-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.phone")}</label>
                        <Field

                            className="w-100 input-style"
                            name="phone"
                            placeholder={t("auth.register.step_1.phone")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="phone" />
                        </div>

                    </div>

                    <div className="col-md-4 mb-md-0 mb-3 px-md-3">
                        <label>{t("auth.register.step_1.phone_op")}</label>
                        <Field
                            className="w-100 input-style"
                            name="additional_phone"
                            placeholder={t("auth.register.step_1.phone_op")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="additional_phone" />
                        </div>
                    </div>

                    <div className="col-md-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.country")}</label>
                        <Field name="country_id">
                            {({ form }) => (
                                <select
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                    value={values.country_id}
                                    name="country_id"
                                    onChange={(e) =>
                                        handleChange(e, form, "country_id")
                                    }
                                >
                                    <option value="" disabled selected>
                                        {t("auth.register.step_1.country")}
                                    </option>

                                    {countriesList.length > 0 && countriesList.map((country) => (
                                        <option value={country.id}>{country.name}</option>
                                    ))}
                                </select>
                            )}
                        </Field>

                        <div className="mt-2 text-danger">
                            <ErrorMessage name="country_id" />
                        </div>
                    </div>

                </div>

                {/* fourth */}
                <div className="row mb-0 mb-md-4 no-gutters">


                    <div className="col-md-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.age")}</label>
                        <Field

                            className="w-100 input-style"
                            name="age"
                            placeholder={t("auth.register.step_1.age")}
                        />
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="age" />
                        </div>
                    </div>

                    <div className="col-md-4 mb-md-0 mb-3 px-md-3">
                        <label>{t("SYC")}</label>
                        <Field name="asylum_type_id">
                            {({ form }) => (
                                <select
                                    value={values.asylum_type_id}
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                    name="asylum_type_id"
                                    onChange={(e) =>
                                        handleChange(e, form, "asylum_type_id")
                                    }
                                >
                                    <option value="" disabled selected>
                                        {t("auth.register.step_1.type")}
                                    </option>

                                    {asylumList.map((type) => (
                                        <option value={type.id}>{type.name}</option>
                                    ))}
                                </select>
                            )}
                        </Field>
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="asylum_type_id" />
                        </div>
                    </div>

                    <div className="col-md-4 mb-md-0 mb-3">
                        <label>{t("auth.register.step_1.gender")}</label>
                        <Field name="gender">
                            {({ form }) => (
                                <select
                                    value={values.gender}
                                    className="w-100 input-style"
                                    style={{ marginTop: "1px" }}
                                    name="gender"
                                    onChange={(e) =>
                                        handleChange(e, form, "gender")
                                    }
                                >
                                    <option value="" disabled selected>
                                        {t("auth.register.step_1.gender")}
                                    </option>

                                    {genderList.map((type) => (
                                        <option value={type.id}>{type.name}</option>
                                    ))}
                                </select>
                            )}
                        </Field>
                        <div className="mt-2 text-danger">
                            <ErrorMessage name="gender" />
                        </div>
                    </div>

                    <div className="col-12 d-flex align-items-center">
                        <label className="m-2">{t('keep_secret')}</label>
                        <Field>
                            {({ form }) => (
                                <input type="checkbox"
                                    checked={values.secret}
                                    id="CheckID"
                                    name="secret"
                                    onChange={() => {
                                        form.setFieldValue('secret', document.getElementById("CheckID").checked ? 1 : 0)
                                    }
                                    }
                                />
                            )}
                        </Field>
                    </div>
                </div>


            </Form >
        )
    }

    // handleValidation
    function handleValidation() {
        const schema = Yup.object().shape({

            username: Yup.string().required(t("req")),
            name: Yup.string().required(t("req")),
            number_id: Yup.string().nullable(),
            email: Yup.string().nullable(),

            password: Yup.string()
                .when("password_confirmation", {
                    is: (value) => value && value.length > 0,
                    then: Yup.string().required(t("req"))
                }),
            password_confirmation: Yup.string()

                .when("password", {
                    is: (value) => value && value.length > 0,
                    then: Yup.string().required(t("req")).oneOf([Yup.ref('password'), null], t("notSame"))
                }),

            phone: Yup.string().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")).required(t("req")),
            additional_phone: Yup.string().nullable().matches(/^[0-9]+$/, t("mustNum")).min(11, t("minNum")).max(11, t("minNum")),

            country_id: Yup.string().required(t("req")),

            age: Yup.string().required(t("req")),
            asylum_type_id: Yup.string().required(t("req")),
            gender: Yup.string().required(t("req")),

        }, ["password", "password_confirmation"])
        return schema;
    }

    return (
        <div className="position-relative">
            {
                isLoading
                &&
                <div className="text-center spinner-container">
                    <i className="fas fa-circle-notch fa-spin fa-4x"></i>
                </div>
            }

            <div className="min-H container-fluid my-5 SP-Dash px-5">

                < Toast position="top-center" ref={toast} />

                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}

                    // validation schema
                    validationSchema={handleValidation}
                >

                    {FormBody}

                </Formik>

            </div>
        </div>
    )
}

export default MigrantAcc
