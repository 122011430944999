import React from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { logout } from '../../store/actions/auth';
import { clearTemp } from '../../store/actions/temp';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

function Sidebar() {

    const dispatch = useDispatch();

    const access_token = useSelector((state) => state.authReducer.access_token);

    const handleLogout = async () => {
        try {

            let headers = {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${access_token}`,
                }
            }

            const resp = await axios.post("logout", {}, headers);
            window.location = '/';
            // window.location = '/login/service_provider';
            dispatch(logout());
            dispatch(clearTemp());

        } catch (err) {
            // Handle Error Here
            console.error(err);
        }
    }

    const { t } = useTranslation();

    return (
        <div className='SB-cont'>
            <div className="row">
                <img src="/img/home/logo.svg" alt="logo" />
            </div>
            <ul className="row list-unstyled px-3 mt-5">
                {/* <li className='col-12 mb-2'><NavLink to="/admin/statistics">{t("admin.statistics")}</NavLink></li> */}
                <li className='col-12 mb-2'><NavLink  to="/admin/news">{t("nav2.a_3")}</NavLink></li>
                {/* <li className='col-12 mb-2'><NavLink  to="/admin/service-providers">{t("nav2.a_3")}</NavLink></li> */}
                {/* <li className='col-12 mb-2'><NavLink to="/admin/news">{t("nav2.a_5")}</NavLink></li> */}
                <li className='col-12 mb-2'><NavLink to="/admin/resources">{t("admin.resources")}</NavLink></li>
                {/* <li className='col-12 mb-2'><NavLink to="/admin/service-providers/requests">{t("admin.requests")}</NavLink></li> */}

                <li className='logout col-12' onClick={handleLogout}><NavLink to="#">{t("logout")}</NavLink></li>
            </ul>
        </div>
    )
}

export default Sidebar
