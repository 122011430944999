import axios from 'axios';
import React from 'react'
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';
import { Formik, Field } from "formik";

export default function Filters({ onSubmit, ...props }) {

    const { t } = useTranslation();
    const Lang = localStorage.getItem("i18nextLng");

    const districtFilterInput = React.useRef(null);
    const [districts, setDistricts] = React.useState([]);
    const [asylumTypes, setAsylumTypes] = React.useState([]);
    const [governorates, setGovernorates] = React.useState([]);

    const [isActive, setIsActive] = React.useState(false);

    const [serviceProviders, setServiceProviders] = React.useState([]);
    const [serviceCategories, setServiceCategories] = React.useState([]);

    const [districtId, setDistrictId] = React.useState("");
    const [asylumTypeId, setAsylumTypeId] = React.useState("");
    const [governorateId, setGovernorateId] = React.useState("");
    const [serviceProviderId, setServiceProviderId] = React.useState("");
    const [serviceCategoryId, setServiceCategoryId] = React.useState("");
    const hideServiceProviders = props.except && props.except.includes('serviceProviders');

    React.useEffect(() => {

        axios(`/governorates`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setGovernorates(response.data.data);
            })

        axios(`/asylum-types`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setAsylumTypes(response.data.data);
            })

        // axios(`/service-categories`, { 'headers': { 'locale': Lang } })
        //     .then((response) => {
        //         setServiceCategories(response.data.data);
        //     })
        axios(`/service-provider?serviceCategoryId`, { 'headers': { 'locale': Lang } })
        .then((response) => {
            setServiceCategories(response.data.data.collection);
        })


        if (!hideServiceProviders) {
            axios(`/service-provider`, { 'headers': { 'locale': Lang } })
                .then((response) => {
                    setServiceProviders(response.data.data);
                })
        }


    }, [Lang])

    function getDistricts(governorateId) {
        axios(`/governorate/${governorateId}/districts`, { 'headers': { 'locale': Lang } })
            .then((response) => {
                setDistricts(response.data.data);
                if (districtFilterInput) {
                    districtFilterInput.current.selectedIndex = 0
                }
            })
    }

    function handleSubmit(values) {

        let valuesToSend = {
            'search': values.search || "",
            'serviceProviderId': serviceProviderId || "",
            'governorateId': governorateId || "",
            'districtId': districtId || "",
            'serviceCategoryId': serviceCategoryId || "",
            'asylumTypeId': asylumTypeId || "",
        };

        onSubmit(valuesToSend)
    }

    function handleToggle() {
        setIsActive(!isActive);
    }

    function formBody({ handleSubmit }) {

        return (

            <>
                <button className="d-lg-none reset-button open-btn" type='button' onClick={handleToggle}>
                    <i className="fa fa-filter" />
                </button>

                <form className={isActive ? "active filter-form" : "filter-form"} onSubmit={handleSubmit}>

                    <button onClick={handleToggle} type='button' className="toggle-btn reset-button d-lg-none">
                        <i className="fas fa-times"></i>
                    </button>

                    <div className="form-row mb-4">

                        {/* <div className="form-group col-md-12">
                            <Field
                                className="input-style form-control"
                                type="text"
                                name="search"
                                icon="far fa-envelope"
                                placeholder={t("filters.search")}
                            />
                        </div> */}

                        {/* <div className="form-group col-md-4">
                            <Dropdown
                                appendTo="self"
                                showClear
                                optionValue='id'
                                optionLabel='name'
                                value={governorateId}
                                options={governorates}
                                className='input-style form-control text-center'
                                placeholder={t('filters.city')}
                                onChange={(e) => { setGovernorateId(e.target.value); e.target.value && getDistricts(e.target.value); !e.target.value && setDistrictId("") }}
                            />
                        </div> */}

                        {/* <div className="form-group col-md-4">
                            <Dropdown
                                appendTo="self"
                                showClear
                                optionValue='id'
                                optionLabel='name'
                                disabled={!governorateId}
                                ref={districtFilterInput}
                                value={districtId}
                                options={districts}
                                className='input-style form-control text-center'
                                placeholder={t('filters.district')}
                                onChange={(e) => setDistrictId(e.target.value)}
                            />
                        </div> */}

                        {/* <div className="form-group col-md-4 text-center px-auto pr-4">
                            <Dropdown
                                appendTo="self"
                                showClear
                                optionValue='id'
                                optionLabel='name'
                                value={asylumTypeId}
                                options={asylumTypes}
                                className='input-style form-control text-center'
                                placeholder={t('filters.target_population')}
                                onChange={(e) => setAsylumTypeId(e.target.value)}
                            />

                        </div> */}

                        {/* {
                            !hideServiceProviders &&
                            <div className="form-group col-md-4">
                                <Dropdown
                                    appendTo="self"
                                    showClear
                                    optionValue='id'
                                    optionLabel='user.name'
                                    value={serviceProviderId}
                                    options={serviceProviders}
                                    className='input-style form-control text-center'
                                    placeholder={t('filters.service_provider')}
                                    onChange={(e) => setServiceProviderId(e.target.value)}
                                />
                            </div>
                        } */}

                        <div className="form-group col-md-4">
                            <Dropdown
                                appendTo="self"
                                showClear
                                optionValue='id'
                                optionLabel='name'
                                value={serviceCategoryId}
                                options={serviceCategories}
                                className='input-style form-control text-center'
                                placeholder={t('filters.category')}
                                onChange={(e) => setServiceCategoryId(e.target.value)}
                            />
                        </div>

                    </div>

                    <button className="btn btn-primary" type="submit">{t('filters.search')}</button>

                </form>
            </>
        )

    }

    return (
        <div className="filters gray-bg py-lg-5 py-2">
            <div className="container">
                <Formik
                    initialValues={{
                        search: ""
                    }}

                    onSubmit={handleSubmit}
                >
                    {formBody}
                </Formik>
            </div>
        </div>
    )

}
